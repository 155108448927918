@font-face
  font-family: 'karla'
  font-display: swap
  src: local('Karla Light'), local('Karla-Light'), url('/themes/hexer/assets/css/fonts/Karla-Light.ttf') format('truetype')
  font-weight: 300
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'karla'
  font-display: swap
  src: local('Karla Regular'), local('Karla-Regular'), url('/themes/hexer/assets/css/fonts/Karla-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'karla'
  font-display: swap
  src: local('Karla Medium'), local('Karla-Medium'), url('/themes/hexer/assets/css/fonts/Karla-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'karla'
  font-display: swap
  src: local('Karla SemiBold'), local('Karla-SemiBold'), url('/themes/hexer/assets/css/fonts/Karla-SemiBold.ttf') format('truetype')
  font-weight: 600
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'karla'
  font-display: swap
  src: local('Karla Bold'), local('Karla-Bold'), url('/themes/hexer/assets/css/fonts/Karla-Bold.ttf') format('truetype')
  font-weight: 900
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'lora'
  font-display: swap
  src: local('Lora Regular'), local('Lora-Regular'), url('/themes/hexer/assets/css/fonts/Lora-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'lora'
  font-display: swap
  src: local('Lora Medium'), local('Lora-Medium'), url('/themes/hexer/assets/css/fonts/Lora-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'lora'
  font-display: swap
  src: local('Lora SemiBold'), local('Lora-SemiBold'), url('/themes/hexer/assets/css/fonts/Lora-SemiBold.ttf') format('truetype')
  font-weight: 600
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'lora'
  font-display: swap
  src: local('Lora Bold'), local('Lora-Bold'), url('/themes/hexer/assets/css/fonts/Lora-Bold.ttf') format('truetype')
  font-weight: 900
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'asap'
  font-display: swap
  src: local('Asap Regular'), local('Asap-Regular'), url('/themes/hexer/assets/css/fonts/Asap-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'asap'
  font-display: swap
  src: local('Asap Medium'), local('Asap-Medium'), url('/themes/hexer/assets/css/fonts/Asap-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'asap'
  font-display: swap
  src: local('Asap SemiBold'), local('Asap-SemiBold'), url('/themes/hexer/assets/css/fonts/Asap-Bold.ttf') format('truetype')
  font-weight: 600
  font-style: normal
  font-stretch: normal
@font-face
  font-family: 'zigzag'
  src:  url('/themes/hexer/assets/css/fonts/icons/zigzag.eot?wbfh8p')
  src:  url('/themes/hexer/assets/css/fonts/icons/zigzag.eot?wbfh8p#iefix') format('embedded-opentype'),url('/themes/hexer/assets/css/fonts/icons/zigzag.ttf?wbfh8p') format('truetype'),url('/themes/hexer/assets/css/fonts/icons/zigzag.woff?wbfh8p') format('woff'),url('/themes/hexer/assets/css/fonts/icons/zigzag.svg?wbfh8p#zigzag') format('svg')
  font-weight: normal
  font-style: normal
  font-display: block

span[class^="icon-"], span[class*=" icon-"]
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'zigzag' !important
  speak: never
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.theme
  font-family: "karla"


.icon-zigzagvt .path1:before
  content: "\e900"
  color: rgb(251, 18, 40)

.icon-zigzagvt .path2:before
  content: "\e90e"
  margin-left: -0.9306640625em
  color: rgb(20, 67, 22)

.icon-zigzagvt .path3:before
  content: "\e915"
  margin-left: -0.9306640625em
  color: rgb(245, 72, 145)

.icon-zigzagvt .path4:before
  content: "\e916"
  margin-left: -0.9306640625em
  color: rgb(66, 41, 7)

.icon-zigzagvt .path5:before
  content: "\e917"
  margin-left: -0.9306640625em
  color: rgb(254, 77, 12)

.icon-zigzagvt .path6:before
  content: "\e918"
  margin-left: -0.9306640625em
  color: rgb(187, 203, 76)

.icon-zigzagvt .path7:before
  content: "\e919"
  margin-left: -0.9306640625em
  color: rgb(148, 33, 140)

.icon-zigzagvt .path8:before
  content: "\e91a"
  margin-left: -0.9306640625em
  color: rgb(73, 170, 43)

.icon-zigzagvt .path9:before
  content: "\e91b"
  margin-left: -0.9306640625em
  color: rgb(255, 255, 255)

.icon-zigzagvt .path10:before
  content: "\e91c"
  margin-left: -0.9306640625em
  color: rgb(255, 201, 38)

.icon-zigzagvt .path11:before
  content: "\e91d"
  margin-left: -0.9306640625em
  color: rgb(84, 186, 166)

.icon-zigzagvt .path12:before
  content: "\e91e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path13:before
  content: "\e91f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path14:before
  content: "\e920"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path15:before
  content: "\e921"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path16:before
  content: "\e922"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path17:before
  content: "\e923"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path18:before
  content: "\e924"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path19:before
  content: "\e925"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path20:before
  content: "\e926"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path21:before
  content: "\e927"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path22:before
  content: "\e928"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path23:before
  content: "\e929"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path24:before
  content: "\e92a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path25:before
  content: "\e92b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path26:before
  content: "\e92c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path27:before
  content: "\e92d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path28:before
  content: "\e92e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path29:before
  content: "\e92f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path30:before
  content: "\e930"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path31:before
  content: "\e931"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path32:before
  content: "\e932"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path33:before
  content: "\e933"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path34:before
  content: "\e934"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path35:before
  content: "\e935"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path36:before
  content: "\e936"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path37:before
  content: "\e937"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path38:before
  content: "\e938"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path39:before
  content: "\e939"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path40:before
  content: "\e93a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path41:before
  content: "\e93b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path42:before
  content: "\e93c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path43:before
  content: "\e93d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path44:before
  content: "\e93e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path45:before
  content: "\e93f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path46:before
  content: "\e940"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path47:before
  content: "\e941"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path48:before
  content: "\e942"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path49:before
  content: "\e943"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path50:before
  content: "\e944"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path51:before
  content: "\e945"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path52:before
  content: "\e946"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path53:before
  content: "\e947"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path54:before
  content: "\e948"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path55:before
  content: "\e949"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path56:before
  content: "\e94a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path57:before
  content: "\e94b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path58:before
  content: "\e94c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path59:before
  content: "\e94d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path60:before
  content: "\e94e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path61:before
  content: "\e94f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path62:before
  content: "\e950"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path63:before
  content: "\e951"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path64:before
  content: "\e952"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path65:before
  content: "\e953"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path66:before
  content: "\e954"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path67:before
  content: "\e955"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path68:before
  content: "\e956"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path69:before
  content: "\e957"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path70:before
  content: "\e958"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path71:before
  content: "\e959"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path72:before
  content: "\e95a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path73:before
  content: "\e95b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path74:before
  content: "\e95c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path75:before
  content: "\e95d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path76:before
  content: "\e95e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path77:before
  content: "\e95f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path78:before
  content: "\e960"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path79:before
  content: "\e961"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path80:before
  content: "\e962"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path81:before
  content: "\e963"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path82:before
  content: "\e964"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path83:before
  content: "\e965"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path84:before
  content: "\e966"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path85:before
  content: "\e967"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path86:before
  content: "\e968"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path87:before
  content: "\e969"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path88:before
  content: "\e96a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path89:before
  content: "\e96b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path90:before
  content: "\e96c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path91:before
  content: "\e96d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path92:before
  content: "\e96e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path93:before
  content: "\e96f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path94:before
  content: "\e970"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path95:before
  content: "\e971"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path96:before
  content: "\e972"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path97:before
  content: "\e973"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path98:before
  content: "\e974"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path99:before
  content: "\e975"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path100:before
  content: "\e976"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path101:before
  content: "\e977"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path102:before
  content: "\e978"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path103:before
  content: "\e979"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path104:before
  content: "\e97a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path105:before
  content: "\e97b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path106:before
  content: "\e97c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path107:before
  content: "\e97d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvt .path108:before
  content: "\e97e"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvt .path109:before
  content: "\e97f"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvt .path110:before
  content: "\e980"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvt .path111:before
  content: "\e981"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvt .path112:before
  content: "\e982"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvt .path113:before
  content: "\e983"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvt .path114:before
  content: "\e984"
  margin-left: -0.9306640625em
  color: rgb(23, 17, 80)

.icon-zigzagvt .path115:before
  content: "\e985"
  margin-left: -0.9306640625em
  color: rgb(23, 17, 80)

.icon-zigzagvt .path116:before
  content: "\e986"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path117:before
  content: "\e987"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path118:before
  content: "\e988"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path119:before
  content: "\e989"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path120:before
  content: "\e98a"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path121:before
  content: "\e98b"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path122:before
  content: "\e98c"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path123:before
  content: "\e98d"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path124:before
  content: "\e98e"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path125:before
  content: "\e98f"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path126:before
  content: "\e990"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path127:before
  content: "\e991"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path128:before
  content: "\e992"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path129:before
  content: "\e993"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path130:before
  content: "\e994"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvt .path131:before
  content: "\e995"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagh .path1:before
  content: "\e996"
  color: rgb(251, 18, 40)

.icon-zigzagh .path2:before
  content: "\e997"
  margin-left: -2.97265625em
  color: rgb(20, 67, 22)

.icon-zigzagh .path3:before
  content: "\e998"
  margin-left: -2.97265625em
  color: rgb(245, 72, 145)

.icon-zigzagh .path4:before
  content: "\e999"
  margin-left: -2.97265625em
  color: rgb(66, 41, 7)

.icon-zigzagh .path5:before
  content: "\e99a"
  margin-left: -2.97265625em
  color: rgb(254, 77, 12)

.icon-zigzagh .path6:before
  content: "\e99b"
  margin-left: -2.97265625em
  color: rgb(187, 203, 76)

.icon-zigzagh .path7:before
  content: "\e99c"
  margin-left: -2.97265625em
  color: rgb(148, 33, 140)

.icon-zigzagh .path8:before
  content: "\e99d"
  margin-left: -2.97265625em
  color: rgb(73, 170, 43)

.icon-zigzagh .path9:before
  content: "\e99e"
  margin-left: -2.97265625em
  color: rgb(255, 255, 255)

.icon-zigzagh .path10:before
  content: "\e99f"
  margin-left: -2.97265625em
  color: rgb(255, 201, 38)

.icon-zigzagh .path11:before
  content: "\e9a0"
  margin-left: -2.97265625em
  color: rgb(84, 186, 166)

.icon-zigzagh .path12:before
  content: "\e9a1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path13:before
  content: "\e9a2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path14:before
  content: "\e9a3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path15:before
  content: "\e9a4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path16:before
  content: "\e9a5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path17:before
  content: "\e9a6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path18:before
  content: "\e9a7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path19:before
  content: "\e9a8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path20:before
  content: "\e9a9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path21:before
  content: "\e9aa"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path22:before
  content: "\e9ab"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path23:before
  content: "\e9ac"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path24:before
  content: "\e9ad"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path25:before
  content: "\e9ae"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path26:before
  content: "\e9af"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path27:before
  content: "\e9b0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path28:before
  content: "\e9b1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path29:before
  content: "\e9b2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path30:before
  content: "\e9b3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path31:before
  content: "\e9b4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path32:before
  content: "\e9b5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path33:before
  content: "\e9b6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path34:before
  content: "\e9b7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path35:before
  content: "\e9b8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path36:before
  content: "\e9b9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path37:before
  content: "\e9ba"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path38:before
  content: "\e9bb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path39:before
  content: "\e9bc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path40:before
  content: "\e9bd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path41:before
  content: "\e9be"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path42:before
  content: "\e9bf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path43:before
  content: "\e9c0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path44:before
  content: "\e9c1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path45:before
  content: "\e9c2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path46:before
  content: "\e9c3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path47:before
  content: "\e9c4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path48:before
  content: "\e9c5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path49:before
  content: "\e9c6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path50:before
  content: "\e9c7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path51:before
  content: "\e9c8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path52:before
  content: "\e9c9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path53:before
  content: "\e9ca"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path54:before
  content: "\e9cb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path55:before
  content: "\e9cc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path56:before
  content: "\e9cd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path57:before
  content: "\e9ce"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path58:before
  content: "\e9cf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path59:before
  content: "\e9d0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path60:before
  content: "\e9d1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path61:before
  content: "\e9d2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path62:before
  content: "\e9d3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path63:before
  content: "\e9d4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path64:before
  content: "\e9d5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path65:before
  content: "\e9d6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path66:before
  content: "\e9d7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path67:before
  content: "\e9d8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path68:before
  content: "\e9d9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path69:before
  content: "\e9da"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path70:before
  content: "\e9db"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path71:before
  content: "\e9dc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path72:before
  content: "\e9dd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path73:before
  content: "\e9de"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path74:before
  content: "\e9df"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path75:before
  content: "\e9e0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path76:before
  content: "\e9e1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path77:before
  content: "\e9e2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path78:before
  content: "\e9e3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path79:before
  content: "\e9e4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path80:before
  content: "\e9e5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path81:before
  content: "\e9e6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path82:before
  content: "\e9e7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path83:before
  content: "\e9e8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path84:before
  content: "\e9e9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path85:before
  content: "\e9ea"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path86:before
  content: "\e9eb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path87:before
  content: "\e9ec"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path88:before
  content: "\e9ed"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path89:before
  content: "\e9ee"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path90:before
  content: "\e9ef"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path91:before
  content: "\e9f0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path92:before
  content: "\e9f1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path93:before
  content: "\e9f2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path94:before
  content: "\e9f3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path95:before
  content: "\e9f4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path96:before
  content: "\e9f5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path97:before
  content: "\e9f6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path98:before
  content: "\e9f7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path99:before
  content: "\e9f8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path100:before
  content: "\e9f9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path101:before
  content: "\e9fa"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path102:before
  content: "\e9fb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path103:before
  content: "\e9fc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path104:before
  content: "\e9fd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path105:before
  content: "\e9fe"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path106:before
  content: "\e9ff"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path107:before
  content: "\ea00"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path108:before
  content: "\ea01"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzagh .path109:before
  content: "\ea02"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzagh .path110:before
  content: "\ea03"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzagh .path111:before
  content: "\ea04"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzagh .path112:before
  content: "\ea05"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzagh .path113:before
  content: "\ea06"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzagh .path114:before
  content: "\ea07"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzagh .path115:before
  content: "\ea08"
  margin-left: -2.97265625em
  color: rgb(23, 17, 80)

.icon-zigzagh .path116:before
  content: "\ea09"
  margin-left: -2.97265625em
  color: rgb(23, 17, 80)

.icon-zigzaght .path1:before
  content: "\ea0a"
  color: rgb(251, 18, 40)

.icon-zigzaght .path2:before
  content: "\ea0b"
  margin-left: -2.97265625em
  color: rgb(20, 67, 22)

.icon-zigzaght .path3:before
  content: "\ea0c"
  margin-left: -2.97265625em
  color: rgb(245, 72, 145)

.icon-zigzaght .path4:before
  content: "\ea0d"
  margin-left: -2.97265625em
  color: rgb(66, 41, 7)

.icon-zigzaght .path5:before
  content: "\ea0e"
  margin-left: -2.97265625em
  color: rgb(254, 77, 12)

.icon-zigzaght .path6:before
  content: "\ea0f"
  margin-left: -2.97265625em
  color: rgb(187, 203, 76)

.icon-zigzaght .path7:before
  content: "\ea10"
  margin-left: -2.97265625em
  color: rgb(148, 33, 140)

.icon-zigzaght .path8:before
  content: "\ea11"
  margin-left: -2.97265625em
  color: rgb(73, 170, 43)

.icon-zigzaght .path9:before
  content: "\ea12"
  margin-left: -2.97265625em
  color: rgb(255, 255, 255)

.icon-zigzaght .path10:before
  content: "\ea13"
  margin-left: -2.97265625em
  color: rgb(255, 201, 38)

.icon-zigzaght .path11:before
  content: "\ea14"
  margin-left: -2.97265625em
  color: rgb(84, 186, 166)

.icon-zigzaght .path12:before
  content: "\ea15"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path13:before
  content: "\ea16"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path14:before
  content: "\ea17"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path15:before
  content: "\ea18"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path16:before
  content: "\ea19"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path17:before
  content: "\ea1a"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path18:before
  content: "\ea1b"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path19:before
  content: "\ea1c"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path20:before
  content: "\ea1d"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path21:before
  content: "\ea1e"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path22:before
  content: "\ea1f"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path23:before
  content: "\ea20"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path24:before
  content: "\ea9e"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path25:before
  content: "\ea9f"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path26:before
  content: "\eaa0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path27:before
  content: "\eaa1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path28:before
  content: "\eaa2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path29:before
  content: "\eaa3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path30:before
  content: "\eaa4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path31:before
  content: "\eaa5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path32:before
  content: "\eaa6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path33:before
  content: "\eaa7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path34:before
  content: "\eaa8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path35:before
  content: "\eaa9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path36:before
  content: "\eaaa"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path37:before
  content: "\eaab"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path38:before
  content: "\eaac"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path39:before
  content: "\eaad"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path40:before
  content: "\eaae"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path41:before
  content: "\eaaf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path42:before
  content: "\eab0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path43:before
  content: "\eab1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path44:before
  content: "\eab2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path45:before
  content: "\eab3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path46:before
  content: "\eab4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path47:before
  content: "\eab5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path48:before
  content: "\eab6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path49:before
  content: "\eab7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path50:before
  content: "\eab8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path51:before
  content: "\eab9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path52:before
  content: "\eaba"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path53:before
  content: "\eabb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path54:before
  content: "\eabc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path55:before
  content: "\eabd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path56:before
  content: "\eabe"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path57:before
  content: "\eabf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path58:before
  content: "\eac0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path59:before
  content: "\eac1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path60:before
  content: "\eac2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path61:before
  content: "\eac3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path62:before
  content: "\eac4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path63:before
  content: "\eac5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path64:before
  content: "\eac6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path65:before
  content: "\eac7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path66:before
  content: "\eac8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path67:before
  content: "\eac9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path68:before
  content: "\eaca"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path69:before
  content: "\eacb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path70:before
  content: "\eacc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path71:before
  content: "\eacd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path72:before
  content: "\eace"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path73:before
  content: "\eacf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path74:before
  content: "\ead0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path75:before
  content: "\ead1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path76:before
  content: "\ead2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path77:before
  content: "\ead3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path78:before
  content: "\ead4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path79:before
  content: "\ead5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path80:before
  content: "\ead6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path81:before
  content: "\ead7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path82:before
  content: "\ead8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path83:before
  content: "\ead9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path84:before
  content: "\eada"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path85:before
  content: "\eadb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path86:before
  content: "\eadc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path87:before
  content: "\eadd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path88:before
  content: "\eade"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path89:before
  content: "\eadf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path90:before
  content: "\eae0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path91:before
  content: "\eae1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path92:before
  content: "\eae2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path93:before
  content: "\eae3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path94:before
  content: "\eae4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path95:before
  content: "\eae5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path96:before
  content: "\eae6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path97:before
  content: "\eae7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path98:before
  content: "\eae8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path99:before
  content: "\eae9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path100:before
  content: "\eaea"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path101:before
  content: "\eaeb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path102:before
  content: "\eaec"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path103:before
  content: "\eaed"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path104:before
  content: "\eaee"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path105:before
  content: "\eaef"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path106:before
  content: "\eaf0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path107:before
  content: "\eaf1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path108:before
  content: "\eaf2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaght .path109:before
  content: "\eaf3"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaght .path110:before
  content: "\eaf4"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaght .path111:before
  content: "\eaf5"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaght .path112:before
  content: "\eaf6"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaght .path113:before
  content: "\eaf7"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaght .path114:before
  content: "\eaf8"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaght .path115:before
  content: "\eaf9"
  margin-left: -2.97265625em
  color: rgb(23, 17, 80)

.icon-zigzaght .path116:before
  content: "\eafa"
  margin-left: -2.97265625em
  color: rgb(23, 17, 80)

.icon-zigzaght .path117:before
  content: "\eafb"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path118:before
  content: "\eafc"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path119:before
  content: "\eafd"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path120:before
  content: "\eafe"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path121:before
  content: "\eaff"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path122:before
  content: "\eb00"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path123:before
  content: "\eb01"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path124:before
  content: "\eb02"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path125:before
  content: "\eb03"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path126:before
  content: "\eb04"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path127:before
  content: "\eb05"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path128:before
  content: "\eb06"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path129:before
  content: "\eb07"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path130:before
  content: "\eb08"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path131:before
  content: "\eb09"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaght .path132:before
  content: "\eb0a"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-phone:before
  content: "\eb0b"
  color: #fff

.icon-arrowdown:before
  content: "\eb2c"
  color: #7980c2

.icon-arrowr:before
  content: "\e901"
  color: #7980c2

.icon-arrowtop:before
  content: "\e902"
  color: #fff

.icon-atencion:before
  content: "\e903"
  color: #7980c2

.icon-calidad:before
  content: "\e904"
  color: #7980c2

.icon-cart:before
  content: "\e905"
  color: #515151

.icon-cercania:before
  content: "\e906"
  color: #7980c2

.icon-close:before
  content: "\e907"

.icon-experiencia:before
  content: "\e908"
  color: #7980c2

.icon-facebook:before
  content: "\e909"
  color: #fff

.icon-filters:before
  content: "\e90a"
  color: #73cdc8

.icon-instagram:before
  content: "\e90b"
  color: #fff

.icon-menu:before
  content: "\e90c"

.icon-menu2:before
  content: "\e90d"
  color: #73cdc8

.icon-profesionalidad:before
  content: "\eb2b"
  color: #7980c2

.icon-search:before
  content: "\e90f"
  color: #515151

.icon-twitter:before
  content: "\e910"
  color: #fff

.icon-user:before
  content: "\e911"
  color: #515151

.icon-versatilidad:before
  content: "\e912"
  color: #7980c2

.icon-whatsap:before
  content: "\e913"
  color: #fff

.icon-wish:before
  content: "\e914"
  color: #515151

.icon-zigzagv .path1:before
  content: "\ea21"
  color: rgb(251, 18, 40)

.icon-zigzagv .path2:before
  content: "\ea22"
  margin-left: -0.93359375em
  color: rgb(20, 67, 22)

.icon-zigzagv .path3:before
  content: "\ea23"
  margin-left: -0.93359375em
  color: rgb(245, 72, 145)

.icon-zigzagv .path4:before
  content: "\ea24"
  margin-left: -0.93359375em
  color: rgb(66, 41, 7)

.icon-zigzagv .path5:before
  content: "\ea25"
  margin-left: -0.93359375em
  color: rgb(254, 77, 12)

.icon-zigzagv .path6:before
  content: "\ea26"
  margin-left: -0.93359375em
  color: rgb(187, 203, 76)

.icon-zigzagv .path7:before
  content: "\ea27"
  margin-left: -0.93359375em
  color: rgb(148, 33, 140)

.icon-zigzagv .path8:before
  content: "\ea28"
  margin-left: -0.93359375em
  color: rgb(73, 170, 43)

.icon-zigzagv .path9:before
  content: "\ea29"
  margin-left: -0.93359375em
  color: rgb(255, 255, 255)

.icon-zigzagv .path10:before
  content: "\ea2a"
  margin-left: -0.93359375em
  color: rgb(255, 201, 38)

.icon-zigzagv .path11:before
  content: "\ea2b"
  margin-left: -0.93359375em
  color: rgb(84, 186, 166)

.icon-zigzagv .path12:before
  content: "\ea2c"
  margin-left: -0.93359375em
  color: rgb(24, 19, 77)

.icon-zigzagv .path13:before
  content: "\ea2d"
  margin-left: -0.93359375em
  color: rgb(24, 19, 77)

.icon-zigzagv .path14:before
  content: "\ea2e"
  margin-left: -0.93359375em
  color: rgb(24, 19, 77)

.icon-zigzagv .path15:before
  content: "\ea2f"
  margin-left: -0.93359375em
  color: rgb(24, 19, 77)

.icon-zigzagv .path16:before
  content: "\ea30"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path17:before
  content: "\ea31"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path18:before
  content: "\ea32"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path19:before
  content: "\ea33"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path20:before
  content: "\ea34"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path21:before
  content: "\ea35"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path22:before
  content: "\ea36"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path23:before
  content: "\ea37"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path24:before
  content: "\ea38"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path25:before
  content: "\ea39"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path26:before
  content: "\ea3a"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path27:before
  content: "\ea3b"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path28:before
  content: "\ea3c"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path29:before
  content: "\ea3d"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path30:before
  content: "\ea3e"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path31:before
  content: "\ea3f"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path32:before
  content: "\ea40"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path33:before
  content: "\ea41"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path34:before
  content: "\ea42"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path35:before
  content: "\ea43"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path36:before
  content: "\ea44"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path37:before
  content: "\ea45"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path38:before
  content: "\ea46"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path39:before
  content: "\ea47"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path40:before
  content: "\ea48"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path41:before
  content: "\ea49"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path42:before
  content: "\ea4a"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path43:before
  content: "\ea4b"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path44:before
  content: "\ea4c"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path45:before
  content: "\ea4d"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path46:before
  content: "\ea4e"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path47:before
  content: "\ea4f"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path48:before
  content: "\ea50"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path49:before
  content: "\ea51"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path50:before
  content: "\ea52"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path51:before
  content: "\ea53"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path52:before
  content: "\ea54"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path53:before
  content: "\ea55"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path54:before
  content: "\ea56"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path55:before
  content: "\ea57"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path56:before
  content: "\ea58"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path57:before
  content: "\ea59"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path58:before
  content: "\ea5a"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path59:before
  content: "\ea5b"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path60:before
  content: "\ea5c"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path61:before
  content: "\ea5d"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path62:before
  content: "\ea5e"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path63:before
  content: "\ea5f"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path64:before
  content: "\ea60"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path65:before
  content: "\ea61"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path66:before
  content: "\ea62"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path67:before
  content: "\ea63"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path68:before
  content: "\ea64"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path69:before
  content: "\ea65"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path70:before
  content: "\ea66"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path71:before
  content: "\ea67"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path72:before
  content: "\ea68"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path73:before
  content: "\ea69"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path74:before
  content: "\ea6a"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path75:before
  content: "\ea6b"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path76:before
  content: "\ea6c"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path77:before
  content: "\ea6d"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path78:before
  content: "\ea6e"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path79:before
  content: "\ea6f"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path80:before
  content: "\ea70"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path81:before
  content: "\ea71"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path82:before
  content: "\ea72"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path83:before
  content: "\ea73"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path84:before
  content: "\ea74"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path85:before
  content: "\ea75"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path86:before
  content: "\ea76"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path87:before
  content: "\ea77"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path88:before
  content: "\ea78"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path89:before
  content: "\ea79"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path90:before
  content: "\ea7a"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path91:before
  content: "\ea7b"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path92:before
  content: "\ea7c"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path93:before
  content: "\ea7d"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path94:before
  content: "\ea7e"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path95:before
  content: "\ea7f"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path96:before
  content: "\ea80"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path97:before
  content: "\ea81"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path98:before
  content: "\ea82"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path99:before
  content: "\ea83"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path100:before
  content: "\ea84"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path101:before
  content: "\ea85"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path102:before
  content: "\ea86"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path103:before
  content: "\ea87"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path104:before
  content: "\ea88"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path105:before
  content: "\ea89"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path106:before
  content: "\ea8a"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path107:before
  content: "\ea8b"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path108:before
  content: "\ea8c"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path109:before
  content: "\ea8d"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path110:before
  content: "\ea8e"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path111:before
  content: "\ea8f"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path112:before
  content: "\ea90"
  margin-left: -0.93359375em
  color: rgb(79, 70, 115)

.icon-zigzagv .path113:before
  content: "\ea91"
  margin-left: -0.93359375em
  color: rgb(139, 131, 154)

.icon-zigzagv .path114:before
  content: "\ea92"
  margin-left: -0.93359375em
  color: rgb(139, 131, 154)

.icon-zigzagv .path115:before
  content: "\ea93"
  margin-left: -0.93359375em
  color: rgb(139, 131, 154)

.icon-zigzagv .path116:before
  content: "\ea94"
  margin-left: -0.93359375em
  color: rgb(139, 131, 154)

.icon-zigzagv .path117:before
  content: "\ea95"
  margin-left: -0.93359375em
  color: rgb(139, 131, 154)

.icon-zigzagv .path118:before
  content: "\ea96"
  margin-left: -0.93359375em
  color: rgb(139, 131, 154)

.icon-zigzagv .path119:before
  content: "\ea97"
  margin-left: -0.93359375em
  color: rgb(23, 17, 80)

.icon-zigzagv .path120:before
  content: "\ea98"
  margin-left: -0.93359375em
  color: rgb(23, 17, 80)

.icon-zigzagv .path121:before
  content: "\ea99"
  margin-left: -0.93359375em
  color: rgb(23, 17, 80)

.icon-zigzagv .path122:before
  content: "\ea9a"
  margin-left: -0.93359375em
  color: rgb(23, 17, 80)

.icon-zigzagv .path123:before
  content: "\ea9b"
  margin-left: -0.93359375em
  color: rgb(23, 17, 80)

.icon-zigzagv .path124:before
  content: "\ea9c"
  margin-left: -0.93359375em
  color: rgb(23, 17, 80)

.icon-zigzagv .path125:before
  content: "\ea9d"
  margin-left: -0.93359375em
  color: rgb(23, 17, 80)
/* LOGOS V2 */

.icon-zigzagvtv2 .path1:before
  content: "\eb0c"
  color: rgb(251, 18, 40)

.icon-zigzagvtv2 .path2:before
  content: "\eb0d"
  margin-left: -0.9306640625em
  color: rgb(20, 67, 22)

.icon-zigzagvtv2 .path3:before
  content: "\eb0e"
  margin-left: -0.9306640625em
  color: rgb(245, 72, 145)

.icon-zigzagvtv2 .path4:before
  content: "\eb0f"
  margin-left: -0.9306640625em
  color: rgb(66, 41, 7)

.icon-zigzagvtv2 .path5:before
  content: "\eb10"
  margin-left: -0.9306640625em
  color: rgb(254, 77, 12)

.icon-zigzagvtv2 .path6:before
  content: "\eb11"
  margin-left: -0.9306640625em
  color: rgb(187, 203, 76)

.icon-zigzagvtv2 .path7:before
  content: "\eb12"
  margin-left: -0.9306640625em
  color: rgb(148, 33, 140)

.icon-zigzagvtv2 .path8:before
  content: "\eb13"
  margin-left: -0.9306640625em
  color: rgb(73, 170, 43)

.icon-zigzagvtv2 .path9:before
  content: "\eb14"
  margin-left: -0.9306640625em
  color: rgb(255, 255, 255)

.icon-zigzagvtv2 .path10:before
  content: "\eb15"
  margin-left: -0.9306640625em
  color: rgb(255, 201, 38)

.icon-zigzagvtv2 .path11:before
  content: "\eb16"
  margin-left: -0.9306640625em
  color: rgb(84, 186, 166)

.icon-zigzagvtv2 .path12:before
  content: "\eb17"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path13:before
  content: "\eb18"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path14:before
  content: "\eb19"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path15:before
  content: "\eb1a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path16:before
  content: "\eb1b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path17:before
  content: "\eb1c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path18:before
  content: "\eb1d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path19:before
  content: "\eb1e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path20:before
  content: "\eb1f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path21:before
  content: "\eb20"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path22:before
  content: "\eb21"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path23:before
  content: "\eb22"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path24:before
  content: "\eb23"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path25:before
  content: "\eb24"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path26:before
  content: "\eb25"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path27:before
  content: "\eb26"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path28:before
  content: "\eb27"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path29:before
  content: "\eb28"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path30:before
  content: "\eb29"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path31:before
  content: "\eb2a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path32:before
  content: "\eb2d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path33:before
  content: "\eb2e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path34:before
  content: "\eb2f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path35:before
  content: "\eb30"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path36:before
  content: "\eb31"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path37:before
  content: "\eb32"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path38:before
  content: "\eb33"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path39:before
  content: "\eb34"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path40:before
  content: "\eb35"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path41:before
  content: "\eb36"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path42:before
  content: "\eb37"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path43:before
  content: "\eb38"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path44:before
  content: "\eb39"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path45:before
  content: "\eb3a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path46:before
  content: "\eb3b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path47:before
  content: "\eb3c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path48:before
  content: "\eb3d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path49:before
  content: "\eb3e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path50:before
  content: "\eb3f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path51:before
  content: "\eb40"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path52:before
  content: "\eb41"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path53:before
  content: "\eb42"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path54:before
  content: "\eb43"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path55:before
  content: "\eb44"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path56:before
  content: "\eb45"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path57:before
  content: "\eb46"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path58:before
  content: "\eb47"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path59:before
  content: "\eb48"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path60:before
  content: "\eb49"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path61:before
  content: "\eb4a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path62:before
  content: "\eb4b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path63:before
  content: "\eb4c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path64:before
  content: "\eb4d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path65:before
  content: "\eb4e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path66:before
  content: "\eb4f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path67:before
  content: "\eb50"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path68:before
  content: "\eb51"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path69:before
  content: "\eb52"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path70:before
  content: "\eb53"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path71:before
  content: "\eb54"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path72:before
  content: "\eb55"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path73:before
  content: "\eb56"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path74:before
  content: "\eb57"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path75:before
  content: "\eb58"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path76:before
  content: "\eb59"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path77:before
  content: "\eb5a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path78:before
  content: "\eb5b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path79:before
  content: "\eb5c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path80:before
  content: "\eb5d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path81:before
  content: "\eb5e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path82:before
  content: "\eb5f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path83:before
  content: "\eb60"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path84:before
  content: "\eb61"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path85:before
  content: "\eb62"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path86:before
  content: "\eb63"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path87:before
  content: "\eb64"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path88:before
  content: "\eb65"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path89:before
  content: "\eb66"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path90:before
  content: "\eb67"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path91:before
  content: "\eb68"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path92:before
  content: "\eb69"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path93:before
  content: "\eb6a"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path94:before
  content: "\eb6b"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path95:before
  content: "\eb6c"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path96:before
  content: "\eb6d"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path97:before
  content: "\eb6e"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path98:before
  content: "\eb6f"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path99:before
  content: "\eb70"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path100:before
  content: "\eb71"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path101:before
  content: "\eb72"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path102:before
  content: "\eb73"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path103:before
  content: "\eb74"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path104:before
  content: "\eb75"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path105:before
  content: "\eb76"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path106:before
  content: "\eb77"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path107:before
  content: "\eb78"
  margin-left: -0.9306640625em
  color: rgb(79, 70, 115)

.icon-zigzagvtv2 .path108:before
  content: "\eb79"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvtv2 .path109:before
  content: "\eb7a"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvtv2 .path110:before
  content: "\eb7b"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvtv2 .path111:before
  content: "\eb7c"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvtv2 .path112:before
  content: "\eb7d"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvtv2 .path113:before
  content: "\eb7e"
  margin-left: -0.9306640625em
  color: rgb(139, 131, 154)

.icon-zigzagvtv2 .path114:before
  content: "\eb7f"
  margin-left: -0.9306640625em
  color: rgb(23, 17, 80)

.icon-zigzagvtv2 .path115:before
  content: "\eb80"
  margin-left: -0.9306640625em
  color: rgb(23, 17, 80)

.icon-zigzagvtv2 .path116:before
  content: "\eb81"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path117:before
  content: "\eb82"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path118:before
  content: "\eb83"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path119:before
  content: "\eb84"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path120:before
  content: "\eb85"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path121:before
  content: "\eb86"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path122:before
  content: "\eb87"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path123:before
  content: "\eb88"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path124:before
  content: "\eb89"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path125:before
  content: "\eb8a"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path126:before
  content: "\eb8b"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path127:before
  content: "\eb8c"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path128:before
  content: "\eb8d"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path129:before
  content: "\eb8e"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path130:before
  content: "\eb8f"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzagvtv2 .path131:before
  content: "\eb90"
  margin-left: -0.9306640625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path1:before
  content: "\eb91"
  color: rgb(251, 18, 40)

.icon-zigzaghtv2 .path2:before
  content: "\eb92"
  margin-left: -2.97265625em
  color: rgb(20, 67, 22)

.icon-zigzaghtv2 .path3:before
  content: "\eb93"
  margin-left: -2.97265625em
  color: rgb(245, 72, 145)

.icon-zigzaghtv2 .path4:before
  content: "\eb94"
  margin-left: -2.97265625em
  color: rgb(66, 41, 7)

.icon-zigzaghtv2 .path5:before
  content: "\eb95"
  margin-left: -2.97265625em
  color: rgb(254, 77, 12)

.icon-zigzaghtv2 .path6:before
  content: "\eb96"
  margin-left: -2.97265625em
  color: rgb(187, 203, 76)

.icon-zigzaghtv2 .path7:before
  content: "\eb97"
  margin-left: -2.97265625em
  color: rgb(148, 33, 140)

.icon-zigzaghtv2 .path8:before
  content: "\eb98"
  margin-left: -2.97265625em
  color: rgb(73, 170, 43)

.icon-zigzaghtv2 .path9:before
  content: "\eb99"
  margin-left: -2.97265625em
  color: rgb(255, 255, 255)

.icon-zigzaghtv2 .path10:before
  content: "\eb9a"
  margin-left: -2.97265625em
  color: rgb(255, 201, 38)

.icon-zigzaghtv2 .path11:before
  content: "\eb9b"
  margin-left: -2.97265625em
  color: rgb(84, 186, 166)

.icon-zigzaghtv2 .path12:before
  content: "\eb9c"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path13:before
  content: "\eb9d"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path14:before
  content: "\eb9e"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path15:before
  content: "\eb9f"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path16:before
  content: "\eba0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path17:before
  content: "\eba1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path18:before
  content: "\eba2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path19:before
  content: "\eba3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path20:before
  content: "\eba4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path21:before
  content: "\eba5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path22:before
  content: "\eba6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path23:before
  content: "\eba7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path24:before
  content: "\eba8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path25:before
  content: "\eba9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path26:before
  content: "\ebaa"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path27:before
  content: "\ebab"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path28:before
  content: "\ebac"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path29:before
  content: "\ebad"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path30:before
  content: "\ebae"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path31:before
  content: "\ebaf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path32:before
  content: "\ebb0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path33:before
  content: "\ebb1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path34:before
  content: "\ebb2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path35:before
  content: "\ebb3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path36:before
  content: "\ebb4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path37:before
  content: "\ebb5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path38:before
  content: "\ebb6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path39:before
  content: "\ebb7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path40:before
  content: "\ebb8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path41:before
  content: "\ebb9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path42:before
  content: "\ebba"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path43:before
  content: "\ebbb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path44:before
  content: "\ebbc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path45:before
  content: "\ebbd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path46:before
  content: "\ebbe"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path47:before
  content: "\ebbf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path48:before
  content: "\ebc0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path49:before
  content: "\ebc1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path50:before
  content: "\ebc2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path51:before
  content: "\ebc3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path52:before
  content: "\ebc4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path53:before
  content: "\ebc5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path54:before
  content: "\ebc6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path55:before
  content: "\ebc7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path56:before
  content: "\ebc8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path57:before
  content: "\ebc9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path58:before
  content: "\ebca"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path59:before
  content: "\ebcb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path60:before
  content: "\ebcc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path61:before
  content: "\ebcd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path62:before
  content: "\ebce"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path63:before
  content: "\ebcf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path64:before
  content: "\ebd0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path65:before
  content: "\ebd1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path66:before
  content: "\ebd2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path67:before
  content: "\ebd3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path68:before
  content: "\ebd4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path69:before
  content: "\ebd5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path70:before
  content: "\ebd6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path71:before
  content: "\ebd7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path72:before
  content: "\ebd8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path73:before
  content: "\ebd9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path74:before
  content: "\ebda"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path75:before
  content: "\ebdb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path76:before
  content: "\ebdc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path77:before
  content: "\ebdd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path78:before
  content: "\ebde"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path79:before
  content: "\ebdf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path80:before
  content: "\ebe0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path81:before
  content: "\ebe1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path82:before
  content: "\ebe2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path83:before
  content: "\ebe3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path84:before
  content: "\ebe4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path85:before
  content: "\ebe5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path86:before
  content: "\ebe6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path87:before
  content: "\ebe7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path88:before
  content: "\ebe8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path89:before
  content: "\ebe9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path90:before
  content: "\ebea"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path91:before
  content: "\ebeb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path92:before
  content: "\ebec"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path93:before
  content: "\ebed"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path94:before
  content: "\ebee"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path95:before
  content: "\ebef"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path96:before
  content: "\ebf0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path97:before
  content: "\ebf1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path98:before
  content: "\ebf2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path99:before
  content: "\ebf3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path100:before
  content: "\ebf4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path101:before
  content: "\ebf5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path102:before
  content: "\ebf6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path103:before
  content: "\ebf7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path104:before
  content: "\ebf8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path105:before
  content: "\ebf9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path106:before
  content: "\ebfa"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path107:before
  content: "\ebfb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path108:before
  content: "\ebfc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path109:before
  content: "\ebfd"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path110:before
  content: "\ebfe"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path111:before
  content: "\ebff"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path112:before
  content: "\ec00"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path113:before
  content: "\ec01"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path114:before
  content: "\ec02"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path115:before
  content: "\ec03"
  margin-left: -2.97265625em
  color: rgb(23, 17, 80)

.icon-zigzaghtv2 .path116:before
  content: "\ec04"
  margin-left: -2.97265625em
  color: rgb(23, 17, 80)

.icon-zigzaghtv2 .path117:before
  content: "\ec05"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path118:before
  content: "\ec06"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path119:before
  content: "\ec07"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path120:before
  content: "\ec08"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path121:before
  content: "\ec09"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path122:before
  content: "\ec0a"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path123:before
  content: "\ec0b"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path124:before
  content: "\ec0c"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path125:before
  content: "\ec0d"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path126:before
  content: "\ec0e"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path127:before
  content: "\ec0f"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path128:before
  content: "\ec10"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path129:before
  content: "\ec11"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path130:before
  content: "\ec12"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path131:before
  content: "\ec13"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path132:before
  content: "\ec14"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghv2 .path1:before
  content: "\ec15"
  color: rgb(251, 18, 40)

.icon-zigzaghv2 .path2:before
  content: "\ec16"
  margin-left: -2.97265625em
  color: rgb(20, 67, 22)

.icon-zigzaghv2 .path3:before
  content: "\ec17"
  margin-left: -2.97265625em
  color: rgb(245, 72, 145)

.icon-zigzaghv2 .path4:before
  content: "\ec18"
  margin-left: -2.97265625em
  color: rgb(66, 41, 7)

.icon-zigzaghv2 .path5:before
  content: "\ec19"
  margin-left: -2.97265625em
  color: rgb(254, 77, 12)

.icon-zigzaghv2 .path6:before
  content: "\ec1a"
  margin-left: -2.97265625em
  color: rgb(187, 203, 76)

.icon-zigzaghv2 .path7:before
  content: "\ec1b"
  margin-left: -2.97265625em
  color: rgb(148, 33, 140)

.icon-zigzaghv2 .path8:before
  content: "\ec1c"
  margin-left: -2.97265625em
  color: rgb(73, 170, 43)

.icon-zigzaghv2 .path9:before
  content: "\ec1d"
  margin-left: -2.97265625em
  color: rgb(255, 255, 255)

.icon-zigzaghv2 .path10:before
  content: "\ec1e"
  margin-left: -2.97265625em
  color: rgb(255, 201, 38)

.icon-zigzaghv2 .path11:before
  content: "\ec1f"
  margin-left: -2.97265625em
  color: rgb(84, 186, 166)

.icon-zigzaghv2 .path12:before
  content: "\ec20"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path13:before
  content: "\ec21"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path14:before
  content: "\ec22"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path15:before
  content: "\ec23"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path16:before
  content: "\ec24"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path17:before
  content: "\ec25"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path18:before
  content: "\ec26"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path19:before
  content: "\ec27"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path20:before
  content: "\ec28"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path21:before
  content: "\ec29"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path22:before
  content: "\ec2a"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path23:before
  content: "\ec2b"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path24:before
  content: "\ec2c"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path25:before
  content: "\ec2d"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path26:before
  content: "\ec2e"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path27:before
  content: "\ec2f"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path28:before
  content: "\ec30"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path29:before
  content: "\ec31"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path30:before
  content: "\ec32"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path31:before
  content: "\ec33"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path32:before
  content: "\ec34"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path33:before
  content: "\ec35"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path34:before
  content: "\ec36"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path35:before
  content: "\ec37"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path36:before
  content: "\ec38"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path37:before
  content: "\ec39"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path38:before
  content: "\ec3a"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path39:before
  content: "\ec3b"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path40:before
  content: "\ec3c"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path41:before
  content: "\ec3d"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path42:before
  content: "\ec3e"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path43:before
  content: "\ec3f"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path44:before
  content: "\ec40"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path45:before
  content: "\ec41"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path46:before
  content: "\ec42"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path47:before
  content: "\ec43"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path48:before
  content: "\ec44"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path49:before
  content: "\ec45"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path50:before
  content: "\ec46"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path51:before
  content: "\ec47"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path52:before
  content: "\ec48"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path53:before
  content: "\ec49"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path54:before
  content: "\ec4a"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path55:before
  content: "\ec4b"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path56:before
  content: "\ec4c"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path57:before
  content: "\ec4d"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path58:before
  content: "\ec4e"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path59:before
  content: "\ec4f"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path60:before
  content: "\ec50"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path61:before
  content: "\ec51"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path62:before
  content: "\ec52"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path63:before
  content: "\ec53"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path64:before
  content: "\ec54"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path65:before
  content: "\ec55"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path66:before
  content: "\ec56"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path67:before
  content: "\ec57"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path68:before
  content: "\ec58"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path69:before
  content: "\ec59"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path70:before
  content: "\ec5a"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path71:before
  content: "\ec5b"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path72:before
  content: "\ec5c"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path73:before
  content: "\ec5d"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path74:before
  content: "\ec5e"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path75:before
  content: "\ec5f"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path76:before
  content: "\ec60"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path77:before
  content: "\ec61"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path78:before
  content: "\ec62"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path79:before
  content: "\ec63"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path80:before
  content: "\ec64"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path81:before
  content: "\ec65"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path82:before
  content: "\ec66"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path83:before
  content: "\ec67"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path84:before
  content: "\ec68"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path85:before
  content: "\ec69"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path86:before
  content: "\ec6a"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path87:before
  content: "\ec6b"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path88:before
  content: "\ec6c"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path89:before
  content: "\ec6d"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path90:before
  content: "\ec6e"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path91:before
  content: "\ec6f"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path92:before
  content: "\ec70"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path93:before
  content: "\ec71"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path94:before
  content: "\ec72"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path95:before
  content: "\ec73"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path96:before
  content: "\ec74"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path97:before
  content: "\ec75"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path98:before
  content: "\ec76"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path99:before
  content: "\ec77"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path100:before
  content: "\ec78"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path101:before
  content: "\ec79"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path102:before
  content: "\ec7a"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path103:before
  content: "\ec7b"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path104:before
  content: "\ec7c"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path105:before
  content: "\ec7d"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path106:before
  content: "\ec7e"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path107:before
  content: "\ec7f"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path108:before
  content: "\ec80"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghv2 .path109:before
  content: "\ec81"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghv2 .path110:before
  content: "\ec82"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghv2 .path111:before
  content: "\ec83"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghv2 .path112:before
  content: "\ec84"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghv2 .path113:before
  content: "\ec85"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghv2 .path114:before
  content: "\ec86"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghv2 .path115:before
  content: "\ec87"
  margin-left: -2.97265625em
  color: rgb(23, 17, 80)

.icon-zigzaghv2 .path116:before
  content: "\ec88"
  margin-left: -2.97265625em
  color: rgb(23, 17, 80)


.icon-zigzaghtv2 .path1:before
  content: "\eb91"
  color: rgb(251, 18, 40)

.icon-zigzaghtv2 .path2:before
  content: "\eb92"
  margin-left: -2.97265625em
  color: rgb(20, 67, 22)

.icon-zigzaghtv2 .path3:before
  content: "\eb93"
  margin-left: -2.97265625em
  color: rgb(245, 72, 145)

.icon-zigzaghtv2 .path4:before
  content: "\eb94"
  margin-left: -2.97265625em
  color: rgb(66, 41, 7)

.icon-zigzaghtv2 .path5:before
  content: "\eb95"
  margin-left: -2.97265625em
  color: rgb(254, 77, 12)

.icon-zigzaghtv2 .path6:before
  content: "\eb96"
  margin-left: -2.97265625em
  color: rgb(187, 203, 76)

.icon-zigzaghtv2 .path7:before
  content: "\eb97"
  margin-left: -2.97265625em
  color: rgb(148, 33, 140)

.icon-zigzaghtv2 .path8:before
  content: "\eb98"
  margin-left: -2.97265625em
  color: rgb(73, 170, 43)

.icon-zigzaghtv2 .path9:before
  content: "\eb99"
  margin-left: -2.97265625em
  color: rgb(255, 255, 255)

.icon-zigzaghtv2 .path10:before
  content: "\eb9a"
  margin-left: -2.97265625em
  color: rgb(255, 201, 38)

.icon-zigzaghtv2 .path11:before
  content: "\eb9b"
  margin-left: -2.97265625em
  color: rgb(84, 186, 166)

.icon-zigzaghtv2 .path12:before
  content: "\eb9c"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path13:before
  content: "\eb9d"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path14:before
  content: "\eb9e"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path15:before
  content: "\eb9f"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path16:before
  content: "\eba0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path17:before
  content: "\eba1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path18:before
  content: "\eba2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path19:before
  content: "\eba3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path20:before
  content: "\eba4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path21:before
  content: "\eba5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path22:before
  content: "\eba6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path23:before
  content: "\eba7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path24:before
  content: "\eba8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path25:before
  content: "\eba9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path26:before
  content: "\ebaa"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path27:before
  content: "\ebab"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path28:before
  content: "\ebac"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path29:before
  content: "\ebad"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path30:before
  content: "\ebae"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path31:before
  content: "\ebaf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path32:before
  content: "\ebb0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path33:before
  content: "\ebb1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path34:before
  content: "\ebb2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path35:before
  content: "\ebb3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path36:before
  content: "\ebb4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path37:before
  content: "\ebb5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path38:before
  content: "\ebb6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path39:before
  content: "\ebb7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path40:before
  content: "\ebb8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path41:before
  content: "\ebb9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path42:before
  content: "\ebba"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path43:before
  content: "\ebbb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path44:before
  content: "\ebbc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path45:before
  content: "\ebbd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path46:before
  content: "\ebbe"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path47:before
  content: "\ebbf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path48:before
  content: "\ebc0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path49:before
  content: "\ebc1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path50:before
  content: "\ebc2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path51:before
  content: "\ebc3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path52:before
  content: "\ebc4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path53:before
  content: "\ebc5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path54:before
  content: "\ebc6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path55:before
  content: "\ebc7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path56:before
  content: "\ebc8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path57:before
  content: "\ebc9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path58:before
  content: "\ebca"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path59:before
  content: "\ebcb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path60:before
  content: "\ebcc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path61:before
  content: "\ebcd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path62:before
  content: "\ebce"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path63:before
  content: "\ebcf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path64:before
  content: "\ebd0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path65:before
  content: "\ebd1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path66:before
  content: "\ebd2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path67:before
  content: "\ebd3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path68:before
  content: "\ebd4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path69:before
  content: "\ebd5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path70:before
  content: "\ebd6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path71:before
  content: "\ebd7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path72:before
  content: "\ebd8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path73:before
  content: "\ebd9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path74:before
  content: "\ebda"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path75:before
  content: "\ebdb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path76:before
  content: "\ebdc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path77:before
  content: "\ebdd"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path78:before
  content: "\ebde"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path79:before
  content: "\ebdf"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path80:before
  content: "\ebe0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path81:before
  content: "\ebe1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path82:before
  content: "\ebe2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path83:before
  content: "\ebe3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path84:before
  content: "\ebe4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path85:before
  content: "\ebe5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path86:before
  content: "\ebe6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path87:before
  content: "\ebe7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path88:before
  content: "\ebe8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path89:before
  content: "\ebe9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path90:before
  content: "\ebea"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path91:before
  content: "\ebeb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path92:before
  content: "\ebec"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path93:before
  content: "\ebed"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path94:before
  content: "\ebee"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path95:before
  content: "\ebef"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path96:before
  content: "\ebf0"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path97:before
  content: "\ebf1"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path98:before
  content: "\ebf2"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path99:before
  content: "\ebf3"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path100:before
  content: "\ebf4"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path101:before
  content: "\ebf5"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path102:before
  content: "\ebf6"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path103:before
  content: "\ebf7"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path104:before
  content: "\ebf8"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path105:before
  content: "\ebf9"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path106:before
  content: "\ebfa"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path107:before
  content: "\ebfb"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path108:before
  content: "\ebfc"
  margin-left: -2.97265625em
  color: rgb(79, 70, 115)

.icon-zigzaghtv2 .path109:before
  content: "\ebfd"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path110:before
  content: "\ebfe"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path111:before
  content: "\ebff"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path112:before
  content: "\ec00"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path113:before
  content: "\ec01"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path114:before
  content: "\ec02"
  margin-left: -2.97265625em
  color: rgb(139, 131, 154)

.icon-zigzaghtv2 .path115:before
  content: "\ec03"
  margin-left: -2.97265625em
  color: rgb(23, 17, 80)

.icon-zigzaghtv2 .path116:before
  content: "\ec04"
  margin-left: -2.97265625em
  color: rgb(23, 17, 80)

.icon-zigzaghtv2 .path117:before
  content: "\ec05"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path118:before
  content: "\ec06"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path119:before
  content: "\ec07"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path120:before
  content: "\ec08"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path121:before
  content: "\ec09"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path122:before
  content: "\ec0a"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path123:before
  content: "\ec0b"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path124:before
  content: "\ec0c"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path125:before
  content: "\ec0d"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path126:before
  content: "\ec0e"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path127:before
  content: "\ec0f"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path128:before
  content: "\ec10"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path129:before
  content: "\ec11"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path130:before
  content: "\ec12"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path131:before
  content: "\ec13"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)

.icon-zigzaghtv2 .path132:before
  content: "\ec14"
  margin-left: -2.97265625em
  color: rgb(24, 19, 77)
