@import 'color.sass'
#category,#new-products,#prices-drop
  #seo,#seo2
    p
      padding: 0
  #wrapper .container
    max-width: 1240px
    padding: 0 20px
  .category-sub-menu
    .collapse-icons
      top: 6px
    a
      font-size: 16px
      line-height: 32px
      text-transform: uppercase
      color: $colort
      transition: all .1s linear
      &:hover
        color: $color1
    li[data-depth="0"]
      padding-bottom: 0
      & > a
        border-bottom: 2px solid $colorb
        padding-bottom: 0
      & > .collapse,& > .collapsing
        padding-left: 30px
      li[data-depth="1"]
        margin-bottom: 1px
        .category-sub-link
          font-size: 16px
          line-height: 15px
          text-transform: capitalize
        .category-sub-menu
          margin-top: 3px
  .category-top-menu
    max-height: 100%
  .block-category
    margin: 0
  #left-column
    float: unset
    width: 100%
  #content-wrapper
    float: unset
    width: 100%
  #filters_category .modal-dialog
    width: 90%
    .modal-content
      padding: 0 20px
#category #left-column,#new-products #left-column,#prices-drop #left-column
  position: unset
  .h6,.modal-header > .modal-title
    font-weight: 600
    font-size: 20px
    line-height: 23px
    text-align: center
    letter-spacing: 0.05em
    text-transform: uppercase
    color: $colorb
    display: inline-flex
    align-items: center
    span
      margin-right: 10px
  #search_filters
    & > .h6
      display: none
    .facet
      & > .title
        border-bottom: 2px solid $colorb
      .facet-title
        margin: 0
        font-size: 16px
        line-height: 32px
        text-transform: uppercase
        color: $colort
        transition: all .1s linear
        text-align: left
        padding: 3px 10px
        font-weight: 400
        &:hover
          color: $color1
    .material-icons.remove
      display: none
#js-product-list-top
  .sort-by-row
    display: flex
    justify-content: space-between
    max-width: 360px
    margin: 0 auto
    &::after
      display: none
    #search_filter_toggler
      background: $color3
      user-select: none
      padding: 10px 15px
      display: inline-flex
      justify-content: center
      align-items: center
      border: 1px solid $colorb
      color: $colorb
      font-weight: 600
      font-size: 14px
      line-height: 16px
      text-align: center
      letter-spacing: 0.05em
      text-transform: uppercase
      width: auto
      box-shadow: unset
      .icon-filters
        margin-right: 4px
        &::before
          font-size: -8px
    .btncats
      #opencats
        width: auto
        user-select: none
        padding: 10px 15px
        display: inline-flex
        justify-content: center
        align-items: center
        border: 1px solid $colorb
        color: $colorb
        font-weight: 600
        font-size: 14px
        line-height: 16px
        text-align: center
        letter-spacing: 0.05em
        text-transform: uppercase
        .icon-menu2
          margin-right: 4px
          &::before
            font-size: -8px
#catstree.modal
  transition: all .2s linear
  display: none
  z-index: 999999
  .closeTree
    position: absolute
    right: 20px
    top: 0
    cursor: pointer
    font-size: 1.5rem
    font-weight: 600
    color: #cccccc
  .modal-dialog
    margin: 0
    width: 90%
    max-width: 380px
    height: 100vh
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.4)
    .block-categories
      width: 100%
      height: 100%
#category,#new-products,#prices-drop
  #filters_category
    z-index: 99999
    .modal-dialog
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.4)
#js-product-list
  padding: 0 20px
#js-product-list-header
  width: 100%
  margin-bottom: 15px
  .block-category
    padding: 0
    h1,.h1,.categoryextrafield p
      font-family: "asap"
      font-weight: 600
      font-size: 22px
      line-height: 25px
      text-align: center
      color: $color1
      width: 100%
    p
      font-size: 12px
      line-height: 20px
.pc #left-column
  .titleleft
    width: 100%
    border: 1px solid $colorb
    display: flex !important
    padding: 10px 20px
    align-items: center
    span
      margin-right: 10px
  #search_filters
    .facet.clearfix
      padding-left: 30px
  .category-top-menu
    > li
      &:nth-child(3)
        padding-left: 30px
#category,#new-products,#prices-drop
  .showing
    display: none
  .pagination
    .progress
      display: none
    #btn_inifinitescroll
      border: 1px solid $colorb
      background: $color3
      transition: all .1s linear
      span
        color: $colorb
      &:hover
        border: 1px solid $colorb
        background: $colorb
        transition: all .1s linear
        span
          color: $color3
