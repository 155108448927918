@import 'color.sass'
#header
  border: 0
  padding: 0
  position: relative
  z-index: 99999
  &::after
    content: ""
    background: linear-gradient(0deg, transparent 0%, rgba(0,0,0,0.10) 100%)
    width: 100%
    position: absolute
    bottom: -10px
    height: 10px
  .header-nav
    padding: 15px 20px
    background: $color3
    .container
      padding: 0
      .mobile
        position: relative
        justify-content: space-between
        align-items: center
        margin: 0
        .logo
          font-size: 40px
        #search_widget
          position: absolute
          left: 0
          bottom: 0
          width: 100%
          display: none
        .left
          display: flex
          align-items: center
        .right
          display: flex
          align-items: center
          #opensearch
            margin-right: 20px
            span
              font-size: 20px
          #_mobile_user_info
            margin-right: 20px
            display: inline-flex
            span
              font-size: 20px
          .icon-wish
            font-size: 19px
          #_mobile_cart
            display: inline-flex
            .blockcart
              padding: 0
              span
                font-size: 20px
.menuspace
  width: 40px
.theme
  .ets_mm_megamenu
    position: unset
    margin: 0 !important
    padding: 0 !important
    .ets_mm_megamenu_content_content
      position: unset
      .ybc-menu-toggle
        position: absolute
        left: 0
        top: 8px
        z-index: 9
        width: auto
        .ybc-menu-button-toggle_icon
          margin: 0
    .ybc-menu-toggle.ybc-menu-btn.opened
      .ybc-menu-button-toggle_icon
        .icon-bar:nth-child(1)
          transform: rotate(45deg)
        .icon-bar:nth-child(2)
          transform: rotate(-45deg)
          position: relative
          top: -5px
        .icon-bar:nth-child(3)
          display: none
.theme
  .ets_mm_megamenu_content
    background: $color3 !important
    .container
      width: 100%
  .mm_menus_ul.active
    & > .mm_menus_li
      margin-bottom: 10px
    .novedades
      height: 48px
      display: flex
      align-items: center
      margin-bottom: 0
    .hiddenpc.nosotros
      a
        padding-top: 25px
  .mm_menus_ul:not(.active)
    display: flex
    justify-content: center
    li
      border: 0
      a
        &::before
          display: none
        span
          padding: 18px 20px
          border: 0
          font-family: "karla"
          font-style: normal
          font-weight: 600
          font-size: 12px
          line-height: 19px
          letter-spacing: 0.05em
          text-transform: uppercase
          color: $color4
          transition: all .1s linear
          &:hover
            color: $colorb
#_desktop_logo
  text-align: center
  a
    font-size: 144px
#header .header-top
  .header__flex
    .txticon
      font-size: 14px
      line-height: 16px
      color: $colort2
      transition: all .1s linear
    #search_widget
      min-width: auto
      float: unset
      width: auto
      input
        padding: 5px
        padding-left: 25px
        background: $color3
        border: 0.5px solid #D5D5D5
        font-size: 14px
        line-height: 16px
        color: $colort2
        text-align: right
      button[type="submit"]
        left: 0
    #_desktop_user_info,#_desktop_cart
      &:hover
        .txticon
          color: $colorb
.mm_columns_ul > .mm_has_sub > .mm_blocks_ul > .mm_blocks_li > .ets_mm_block > .ets_mm_block_content > .ets_mm_categories > li > a
  text-transform: uppercase
  font-weight: 600
  font-size: 16px
  line-height: 19px
.pc .noborder.mm_menus_li .mm_columns_ul::after
  display: none
