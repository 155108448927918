@import 'color.sass'
#footer
  #infoFooter .dir a
    text-transform: inherit
  background: #fff
  .pre-footer
    padding: 15px 0
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15)
    margin-bottom: 4px
    .container
      padding: 0 20px
  .h3
    font-family: "asap"
    font-style: normal
    font-weight: 500
    font-size: 18px
    line-height: 21px
    text-align: center
  .footer-container
    background: $colorb
    margin: 0
    padding: 15px 0
    a,.h1, .h2, .h3, span, i, p
      color: $color3
  #logo_footer
    text-align: center
    background: $color3
    padding: 30px 0
    .mobile
      font-size: 64px
    .pc
      font-size: 100px
  .footer-container
    .links
      text-align: center
      .h3
        margin-bottom: 10px
        display: inline-block
      .col-md-12
        &:last-child
          .h3
            margin-top: 15px
      ul
        background: transparent
        li
          border-bottom: 0
          padding: 0
          margin-bottom: 10px
  #block_myaccount_infos
    margin-bottom: 20px
    .title
      text-align: center
      border: 1px solid $color3
      border-radius: 5px
      .h3
        margin-bottom: 0
      .float-xs-left
        position: absolute
        left: 10px
        span
          margin: 0
          color: $color3
          font-size: 8px
          &::before
            color: $color3
      .float-xs-right
        position: absolute
        right: 10px
        span
          margin: 0
          color: $color3
          font-size: 8px
          &::before
            color: $color3
    #footer_account_list
      text-align: center
      margin-top: 15px
#dobuss
  display: flex
  flex-wrap: wrap
  justify-content: center
  align-items: center
  padding: 10px 0
  a
    font-size: 14px
    color: $color2
    margin-right: 10px
.pc .mm_menus_li .mm_columns_ul
  &::after
    content: ""
    position: absolute
    top: 40px
    width: 95%
    height: 1px
    background: $colorb
    z-index: 999999999
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
