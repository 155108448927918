@import 'color.sass'
#carousel
  z-index: 0
  margin: 0
  padding: 0
  left: unset
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: center
  width: 100%
  .carousel-inner
    margin-bottom: 0
    height: auto
  figure
    display: flex
    justify-content: center
    align-items: center
    img
      min-height: 200px
      object-fit: cover
    .caption
      position: absolute
      margin: 0
      bottom: unset
      left: unset
      top: unset
      background: $color3
      display: flex
      flex-wrap: wrap
      justify-content: center
      margin: 10px 50px
      padding-bottom: 20px
      padding-left: 15px
      padding-right: 15px
      outline: 1px solid rgba(50, 51, 102, 0.2)
      outline-offset: -8px
      margin: 0
      margin-left: auto
      margin-right: auto
      max-width: 275px
      .title
        z-index: 1
        color: $color3
        font-size: 12px
        line-height: 14px
        text-align: center
        text-transform: uppercase
        padding: 2px 11px
        background: $color1
        display: inline-block
        margin-bottom: 8px
      .desc
        z-index: 1
        width: 100%
        font-size: 14px
        line-height: 16px
        color: $color1
        p
          font-size: 14px
          line-height: 16px
          color: $color1
          margin-bottom: 5px
          text-align: center
      .link
        z-index: 1
        padding: 10px 15px
        border: 1px solid $colorb
        background: $colorb
        color: $color3
        font-weight: 600
        font-size: 14px
        line-height: 16px
        text-align: center
        letter-spacing: 0.05em
        text-transform: uppercase
        transition: all .1s linear
        &:hover
          background: $color3
          color: $colorb
  .carousel-indicators
    display: none
  .direction
    position: absolute
    top: unset
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    .carousel-control
      position: relative
      top: unset
      bottom: unset
      left: unset
      width: 20px
      height: 20px
      .icon-next
        margin: 0
        position: absolute
        top: 0
        right: 0
        display: flex
        .icon-arrowr
          position: absolute
      .icon-prev
        transform: rotate(180deg)
        position: absolute
        display: flex
        .icon-arrowr
          position: absolute
#seo,#seo2
  width: 100%
  max-width: 980px
  margin: 0 auto
  margin-top: 10px
  margin-bottom: 15px
  text-align: center
  position: relative
  text-align: justify
  .bgseo
    width: 100%
    height: 100%
    object-fit: cover
    position: absolute
    top: 0
    left: 0
    z-index: 0
  .categoryextrafield
    padding: 0
  h1,h2,h3,h4
    color: $color1
  p
    font-size: 14px
    line-height: 30px
    color: $colort
    font-weight: 300
    padding-left: 20px
    padding-right: 20px
  .seovisible
    z-index: 1
    position: relative
    .tituloseo
      width: auto
      display: inline-block
      font-size: 18px
      line-height: 20px
      font-weight: 400
      font-family: "asap"
      color: $color1
      margin-bottom: 5px
      width: 100%
      padding: 0 20px
      h1,.h1
        font-size: 18px
        line-height: 20px
        font-family: "asap"
        color: $color1
        font-weight: 500
        font-size: 20px
        line-height: 23px
        text-align: center
        text-transform: initial
      .top
        img
          width: 100%
          height: 100%
          object-fit: cover
          object-position: center
          min-height: 20px
      .bot
        img
          width: 100%
          height: 100%
          object-fit: cover
          object-position: center
          min-height: 2px
  .abrir:not(.seoinvisible)
    z-index: 1
    margin-bottom: 15px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    span
      cursor: pointer
      display: inline-block
      transition: all .1s linear
      transform: rotate(90deg)
      width: 20px
      height: 20px
      &::before
        font-size: 20px
        display: flex
        width: 100%
        height: 100%
        justify-content: center
        align-items: center
    span.rotated
      transform: rotate(270deg)
  .seoinvisible
    opacity: 1
    height: 100%
    max-height: 2000px
    transition: all .5s linear
    z-index: 1
    position: relative
  .seoinvisible.ocultar
    max-height: 0
    opacity: 0
    overflow: hidden
  .seoinvisible.ocultar.abrir,.seoinvisible.abrir
    max-height: 2000px
    opacity: 1
#splide_newproducts,#splide_featuredproducts,#splide_bestseller,.hc-products-list
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  padding: 0 35px
  & > div
    width: 49%
.theme
  .product-miniature,#products .product-miniature
    margin: 0
    .thumbnail-container
      text-align: center
      a.product-thumbnail
        width: 100%
    .btnd
      margin-top: 10px
      text-transform: capitalize
      font-size: 12px
      line-height: 14px
      letter-spacing: 0.05em
      font-weight: 400
      padding: 10px 20px
      .icon-cart
        &::before
          color: $color3
    .product-list-reviews
      display: none
    .product-title
      padding: 0
      text-align: center
      font-size: 12px
      line-height: 14px
      text-align: center
      letter-spacing: 0.05em
      color: $color2
      font-weight: 400
      height: auto
      margin: 3px 0
    .product-description
      padding: 0
      text-align: center
      .product-price-and-shipping
        text-align: center
        font-weight: 600
        font-size: 12px
        line-height: 14px
        letter-spacing: 0.05em
        color: $color1
        .regular-price
          font-size: 12px
          line-height: 14px
.theme .dbhomecategories
  padding: 20px
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15)
  width: 100%
  max-width: 100%
  margin: 0 auto
  .title
    width: 100%
    max-width: 1200px
    margin: 0 auto
  .row
    display: flex
    flex-wrap: wrap
    margin: 0 auto
    width: 100%
    max-width: 1200px
    li
      width: 100%
      margin-bottom: 5px
      a
        position: relative
        &::before
          content: ""
          position: absolute
          left: 0
          top: 0
          width: 100%
          height: 100%
          background: rgba(0,0,0,0.5)
          transition: all .1s linear
        &:hover
          &::before
            background: rgba(0,0,0,0.25)
        img
          width: 100%
          outline: 1px solid rgba(255, 255, 255, 0.5)
          outline-offset: -8px
        .name
          margin: 0
          position: absolute
          font-size: 18px
          line-height: 21px
          text-align: center
          letter-spacing: 0.05em
          color: $color3
          text-transform: uppercase
          display: inline-flex
          justify-content: center
          align-items: center
          &::before,&::after
            content: ""
            width: 30px
            height: 1px
            background: $color3
          &::before
            margin-right: 7px
          &::after
            margin-left: 7px
.featured-products,.hc-products-list
  padding: 20px 0
  width: 100% !important
  max-width: 1270px
  margin: 0 auto
.featured-products.bestsellers
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15)
  margin-bottom: 5px
.theme
  .homecategorylink
    display: flex
    width: 100%
    justify-content: center
    .btnd
      background: $color3
      color: $colorb
      border: 1px solid $colorb
      font-size: 14px
      line-height: 16px
      font-weight: 600
      text-transform: uppercase
      &:hover
        color: $color3
        background: $colorb
.theme .hc-tab form
  float: unset
#splide_dbbrandslide
  width: 100%
  max-width: 1200px
  margin: 0 auto
#socialBlock
  ul
    display: flex
    flex-wrap: wrap
    justify-content: center
    li
      a
        display: inline-flex
        justify-content: center
        align-items: center
        font-size: 28px
        margin: 0 9px
#infoFooter
  width: 100%
  max-width: 320px
  margin: 0 auto
  .payment
    text-align: center
    margin: 10px 0
    img
      width: 100%
  .copy
    text-align: center
    font-size: 12px
    color: $color3
  .dir
    width: 100%
    a
      display: inline-block
      text-align: center
      text-align: center
      font-size: 12px
      width: 100%
      text-transform: uppercase
      margin-top: 10px
  .phones
    display: flex
    justify-content: space-between
    align-items: center
    a
      font-size: 16px
      display: inline-flex
      align-items: center
      span
        margin-right: 3px
        font-size: 28px

.mobile.search
  position: absolute
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  display: flex
  justify-content: center
  width: 80%
  z-index: 9
  overflow: hidden
  transition: all .2s linear
  max-height: 0
  opacity: 0
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25)
  #search_widget
    width: 100%
    form,input
      width: 100%
    input[type="text"]
      background: $color3
.mobile.search.hidden
  max-height: 50px
  opacity: 1
#footer .block_newsletter
  width: 100%
  max-width: 650px
  margin: 0 auto
  border: 0
  .title_newsletter
    font-family: "asap"
    font-style: normal
    font-weight: 600
    font-size: 22px
    line-height: 25px
    text-align: center
    color: $color1
    margin-bottom: 2px
    letter-spacing: 0
  .subnews
    font-family: "karla"
    font-weight: 400
    font-size: 14px
    line-height: 16px
    color: $colort
    width: 100%
    text-align: center
    display: block
    margin: 0 auto
    margin-bottom: 12px
  .msg_newsletter
    width: 100%
    text-align: center
    margin-bottom: 12px
    .custom-checkbox input[type="checkbox"] + span
      border: 1px solid #73CDC8
    .psgdpr_consent_message
      margin: 0
      font-size: 14px
      line-height: 16px
      color: $colort
      a
        color: $colorb
        transition: all .1s linear
        &:hover
          color: $color1
  input[type="email"]
    width: 100%
    margin-bottom: 10px
    border-radius: 5px
    padding: 5px 11px
    height: auto
    border: 1px solid $colorb
  .btnd
    height: auto
    border: 0
    padding: 10px 15px
/*Acco*/
.ac-container
  // max-width: 400px
  width: 100%
  margin: 10px auto 30px auto
  text-align: left
  & > div
    border: 1px solid $colorb
    border-radius: 5px
    margin-bottom: 10px
  label
    line-height: 23px
    font-size: 20px
    padding: 10px 20px
    position: relative
    z-index: 20
    display: block
    cursor: pointer
    color: $colorb
    border: 0
    transition: all .1s linear
    margin-bottom: 0
    text-align: center
  input
    display: none
  article
    background: rgba(255,255,255,0.5)
    margin-top: -1px
    overflow: hidden
    max-height: 0
    position: relative
    z-index: 10
    transition: all 0.25s linear
    padding: 0 20px
  input:checked ~ label span::before
    transform: rotate(90deg)
  input:checked ~ article
    transition: all 0.25s linear
  input:checked ~ article.ac-small.open
    max-height: 1000px
  input:checked ~ article.ac-medium
    max-height: 1000px
  input:checked ~ article.ac-large
    max-height: 1000px
  .arrowsacc
    position: absolute
    top: 0
    left: 0
    right: 0
    height: 100%
    padding: 10px 20px
    margin-left: auto
    margin-right: auto
    width: 320px
    display: flex
    justify-content: space-between
    align-items: center
    span
      display: flex
      &::before
        font-size: 14px
        color: $color1
        transform: rotate(-90deg)
        transition: all .1s linear
        font-weight: 400
#blockcart-modal
  .modal-dialog
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15)
  #myModalLabel
    color: $color1
    .icon-cart::before
      color: $color1
  .delete_product i,.product-name-value
    color: $color1
  .cart-content
    .label,span
      font-weight: 600
      font-size: 12px
      line-height: 20px
      letter-spacing: 0.05em
      color: $colort
  .cart-content-btn
    .btn-primary
      background: $colorb
      border: 1px solid $colorb
      transition: all .1s linear
      &:hover
        background: $color3
        color: $colorb
        .icon-cart::before
          color: $colorb
    .btn-secundary
      background: $color3
      border: 1px solid $colorb
      color: $colorb !important
      transition: all .1s linear
      &:hover
        background: $colorb !important
        color: $color3 !important
#userinfo_modal .modal-dialog .modal-content
  background: $color3
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15)
  .material-icons
    color: $colorb
  .title,.modal-title
    color: $color1
  .close_session
    border: 1px solid $colorb
    color: $colorb
    transition: all .1s linear
    &:hover
      color: $color3
      background: $colorb
  .item
    display: inline-flex
    align-items: center
    transition: all .1s linear
    .material-icons
      margin-right: 5px
    &:hover
      color: $colorb
.theme .ets_mm_megamenu .mm_columns_ul
  padding-left: 35px
  padding-right: 35px
.theme .pc .ets_mm_megamenu.disable_sticky_mobile
  width: 100%
  max-width: 1200px
  margin: 0 auto !important
  position: relative
  .mm_arrow
    display: none
  &::before
    display: none
  .mm_columns_ul
    display: flex
    flex-wrap: wrap
    .mm_columns_li
      margin: 0 auto
      float: unset
  .mm_blocks_li
    padding: 0
    .ets_mm_block
      .ets_mm_block_content
        .ets_mm_categories
          box-shadow: unset
          & > li.has-sub
            text-align: left
            float: unset
            .arrow
              display: none
            & > a
              text-transform: uppercase
              font-weight: 600
              font-size: 16px
              line-height: 19px
              &::after
                display: none
            .ets_mm_categories
              padding-left: 0
              padding-right: 0
              position: relative
              visibility: visible
              opacity: 1
              left: unset
              margin-left: 0
              border: 0
              display: flex
              flex-wrap: wrap
              white-space: normal
              li
                width: 100%
                margin-left: auto
                margin-right: auto
                margin-bottom: 5px
                display: inline-flex
                justify-content: flex-start
                align-items: center
                padding: 5px 0
                a
                  font-size: 14px
                  line-height: 14px
              &::before
                display: none
  .firstdouble
    .mm_columns_ul
      .mm_columns_li:last-child
        .mm_blocks_li .ets_mm_block .ets_mm_block_content .ets_mm_categories>li.has-sub .ets_mm_categories li
          width: 45%
          margin-left: 0
  .accesoriosMenu
    .mm_columns_ul
      .mm_columns_li:nth-child(1),.mm_columns_li:nth-child(2)
        .mm_blocks_li .ets_mm_block .ets_mm_block_content .ets_mm_categories>li.has-sub .ets_mm_categories li
          width: 45%
          margin-left: 0
header:not(.pc)
  .transition_floating .mm_menus_ul
    top: 70px
    max-width: 100%
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.25)
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.25)
  .mm_has_sub.submenu
    position: relative
    .icon-arrowr
      position: absolute
      right: 50px
      top: 8px
      transition: all .1s linear
      z-index: 99999
    .icon-arrowr.active
      &::before
        transform: rotate(90deg)
  .ets_mm_block_content > .ets_mm_categories > .has-sub > a
    font-weight: 600
.transition_default .mm_menus_ul.active
  z-index: 0 !important
  padding-top: 30px
  height: 100vh !important
#contact
  .col-md-9.col-md-offset-3.offset-md-3
    margin: 0
  .mapa
    position: relative
    bottom: -4px
  #content-wrapper
    #socialBlock
      margin: 20px 0
      a
        span::before
          color: $color1
          font-size: 38px
        &:hover
          span::before
            color: $colorb
    .dir
      width: 100%
      a
        display: inline-block
        text-align: center
        text-align: center
        font-size: 12px
        width: 100%
        font-weight: 300
        margin-top: 10px
        color: $colort
        transition: all .1s linear
        &:hover
          color: $color1
    .phones
      display: flex
      justify-content: space-between
      align-items: center
      width: 100%
      max-width: 300px
      margin: 0 auto
      a
        font-size: 16px
        display: inline-flex
        align-items: center
        color: $colort
        transition: all .1s linear
        &:hover
          color: $color1
        span
          margin-right: 5px
          font-size: 28px
          &::before
            color: $color1
#pfg-form-1,#pfg-form-2
  .col-md-9.col-md-offset-3.offset-md-3
    margin-left: 0
  .h3,h3
    font-family: "asap"
    font-weight: 600
    font-size: 22px
    line-height: 25px
    text-align: center
    color: $color1
    margin-bottom: 15px
  .form-control-label
    display: none
  .form-group.row
    margin-bottom: 10px
    input,textarea
      background: $color3
      border: 0.5px solid $colorb
      border-radius: 5px
      width: 100%
      padding: 5px
      &::placeholder
        font-size: 16px
        line-height: 19px
        color: #7D7D7D
  fieldset
    width: 100%
    max-width: 715px
    margin: 0 auto
    border: 0.5px solid rgba(50, 51, 102, 0.2)
    border-radius: 5px
    padding: 20px
    .col-md-9
      width: 100%
  #gdpr_consent
    width: 100%
    max-width: 1200px
    margin: 0 auto
    padding: 0 20px
    span,a
      font-family: "karla"
      font-weight: 400
      font-size: 12px
      line-height: 26px
      color: $colort2
    a
      color: $colorb
      transition: all .1s linear
      &:hover
        color: $color1
.titlecms
  font-family: "asap"
  font-weight: 600
  font-size: 22px
  line-height: 25px
  text-align: center
  color: $color1
  margin-bottom: 10px
.subtitlecms
  font-family: "asap"
  font-weight: 600
  font-size: 22px
  line-height: 25px
  text-align: center
  color: $color1
  margin-bottom: 10px
  margin-top: 35px
#slidernosotros
  margin: 20px auto
  margin-bottom: 30px
  .slick-dots
    margin-bottom: 0
  .slick-slide
    & > div
      text-align: center
      img
        width: 272px !important
        height: 218px
        object-fit: cover
#iconscms
  display: flex
  flex-wrap: wrap
  width: 100%
  .iconcircle
    display: flex
    flex-wrap: wrap
    justify-content: center
    width: 88px
    padding: 0 5px
    margin: 0 auto
    margin-bottom: 45px
    span:nth-child(1)
      width: 78px
      height: 78px
      border-radius: 50%
      border: 1px solid $colorb
      display: flex
      justify-content: center
      align-items: center
      position: relative
      margin-bottom: 7px
      &::before
        font-size: 35px
        position: absolute
    span:nth-child(2)
      font-size: 10px
      line-height: 12px
      letter-spacing: 0.05em
      color: $color1
      width: 100%
      text-align: center
#bannercms,.bannercms
  position: relative
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 10px
  img
    width: 100%
    object-fit: cover
  a
    position: absolute
    width: 100%
    font-size: 18px
    line-height: 21px
    display: inline-flex
    align-items: center
    text-align: center
    justify-content: center
    letter-spacing: 0.05em
    color: $color3
    padding: 5px 0
#bannercms
  &::before
    content: ""
    background: rgba(50,51,102,0.9)
    width: 100%
    position: absolute
    height: 50px
    left: 0
    top: 0
    bottom: 0
    margin-top: auto
    margin-bottom: auto
    mix-blend-mode: multiply
    z-index: 1
  img
    min-height: 130px
  a
    max-width: 275px
    z-index: 2
    transition: all .1s linear
    &::before
      content: ""
      width: 30px
      height: 1px
      background: $color3
      display: inline-block
    &::after
      content: ""
      width: 30px
      height: 1px
      background: $color3
      display: inline-block
    &:hover
      background: rgba(115,205,200,0.8)
.bannercms
  outline: 0.5px solid rgba(255, 255, 255, 0.5)
  outline-offset: -10px
  img
    min-height: 90px
  a
    transition: all .1s linear
    &:hover
      background: rgba(115,205,200,0.8)
#talleres
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  & > div
    width: 100%
    padding: 20px
    border: 1px solid $colorb
    border-radius: 15px
    margin-bottom: 10px
    p
      text-align: left
      &:last-child
        margin-bottom: 0
    strong
      color: $color1
      font-size: 20px
      line-height: 20px
    b
      color: $colort

.theme
  #splide_images_product_miniature,.product-container .product-cover
    .nxtal-wishlist-icon
      top: unset
      bottom: 40px
      right: 40px
      background: transparent
      span::before
        font-size: 60px
        color: $color1
        transition: all .1s linear
      &:hover
        span::before
          color: red
    .nxtal-wishlist-icon.active
      span::before
        color: red
  .nxtal-product-list .has-discount .discount
    background: $colorb
  .nxtal-product-list .has-discount.product-price, .nxtal-product-list .has-discount p
    color: $colorb
  #_mobile_wishlist_link
    a, a span, a span::before
      transition: all .1s linear
    &:hover
      a, a .txticon, a span::before
        color: $colorb
  .mobile .right
    margin-right: 10px
  #header .header__flex .displayTopCenter
    min-width: auto
  #header
    .nxtal-wishlist-link
      margin-top: 0
      margin-right: 5px
    .header__flex
      .nxtal-wishlist-link
        margin-right: unset
        margin-left: 10px
  .thumbnail-container
    .nxtal-wishlist-icon
      background: transparent
      bottom: 100px
      top: unset
      right: 10px
      padding: 0
      span::before
        color: #7980C2
        transition: all .1s linear
      &:hover
        span::before
          color: red
    .nxtal-wishlist-icon.active
      span::before
        color: red
        font-weight: bold
