@import 'color.sass'
#product
  #ap5-buy-block-container
    padding: 0 20px
    padding-left: 33px
  .ap5-pack-product-content
    height: auto !important
  #ap5-pack-product-tabs-content
    padding: 15px
    border: 2px solid #73cdc8
    margin-top: 2px
  .tabs #ap5-pack-product-tab-list.nav-tabs .nav-link
    border-bottom: 3px solid #ffffff
    transition: all .1s linear
    &.active
      color: #73cdc8
      border-color: #73cdc8
  #ap5-pack-content-block
    .card-header
      background-color: #73cdc8
      color: #ffffff
  #ap5-product-list
    .ap5-color-to-pick-list
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      width: 100%
      li
        width: 35px
        height: 35px
        a
          margin: 0
          display: inline-flex
          width: 100%
          height: 100%
          img
            width: 100%
            height: 100%
            object-fit: cover
  .product-information
    display: none
  .product-container
    display: flex
    flex-wrap: wrap
    justify-content: center
  .images-container
    // max-width: 393px
  #content
    max-width: 450px
  #wrapper .container
    max-width: 1240px
    margin: 0 auto
    padding: 0 20px
  .col-md-6
    padding: 0
  .product-reference
    border-top: 1px solid rgba(50, 51, 102, 0.2)
    border-bottom: 1px solid rgba(50, 51, 102, 0.2)
    span
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 12px
      letter-spacing: 0.05em
      color: $colort
  .product-description
    p:last-child
      margin-bottom: 0
  .titp
    font-weight: 600
    font-size: 12px
    line-height: 20px
    text-align: justify
    letter-spacing: 0.05em
    color: $color1
    margin-right: 3px
    display: inline-block
    float: left
  .titleVariants
    font-family: "asap"
    font-weight: 600
    font-size: 22px
    line-height: 25px
    text-align: center
    color: $color1
    margin-bottom: 25px
    display: inline-block
    width: 100%
  .show_discount
    .title
      display: none
    &:last-child
      display: none
  .product-variants ul li.input-container .selected
    border: 2px solid $colorb
  .product-variants ul li.input-container .selectedname
    color: $color1 !important
  .attrSelected
    font-weight: 600
    font-size: 12px
    line-height: 14px
    letter-spacing: 0.05em
    color: $color1
  .tax-shipping-delivery-label
    display: none
  .product-quantity
    display: flex
    justify-content: space-between
    .qty,.add
      float: unset
      width: auto
      .icon-cart::before
        color: $color3
        transition: all .1s linear
      .add-to-cart
        background: $colorb
        border: 1px solid $colorb
        transition: all .1s linear
        &:hover
          background: $color3
          color: $colorb
          .icon-cart::before
            color: $colorb
    &::after
      display: none
  .product-prices
    .product-price
      border: 0
      padding: 0
      margin: 0
    .current-price
      color: $color1
      margin: 0
      span
        font-size: 16px
      .old_price
        font-size: 12px
  #fixedProduct
    position: sticky
    bottom: 0
    background: $color3
    width: 100%
    left: 0
    padding: 15px 0
    .group
      display: flex
      width: 100%
      justify-content: space-between
      align-items: baseline
      margin-bottom: 10px
    .group,.product-add-to-cart
      padding: 0 20px
  #fixedProduct.isSticky
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)
  .product-variants
    ul
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      width: 100%
      .input-container
        margin: 0 10px
      li.input-container
        display: inline-flex
        flex-wrap: wrap
        justify-content: center
        width: 78px
        margin-bottom: 15px
        .name
          width: 100%
          text-align: center
          font-size: 10px
          line-height: 12px
          text-align: center
          letter-spacing: 0.05em
          color: $colort2
        label
          width: 78px
          height: 78px
          padding: 15px
          border: 0.5px solid rgba(50, 51, 102, 0.2)
          border-radius: 50%
          margin-bottom: 5px
        .color.texture, .custom-checkbox input[type="checkbox"] + span.color.texture
          width: 100%
          height: 100%
          background-size: cover
          background-repeat: no-repeat
          display: block
          border: 0
          box-sizing: border-box
          transition: all .1s linear
          margin: 0
          &:hover
            border-color: $colorb
        .color.texture[checked="checked"], .custom-checkbox input[type="checkbox"] + span.color.texture[checked="checked"]
          border-color: $colorb
        span.color
          width: 100%
          height: 100%
  .splide__slide
    text-align: center
  .splide__arrow
    background: transparent
    svg
      fill: $colorb
  #splide_images_product_secundary
    .splide__list
      .splide__slide
        border: 0.5px solid rgba(50, 51, 102, 0.2)
  .js-qv-product-cover
    border: 0.5px solid rgba(50, 51, 102, 0.2)
    width: 100%
    height: 100%
    object-fit: contain
    object-position: center
  .db_list_comments_products
    margin-bottom: 5px
    width: 100%
  .product-accessories
    width: 100%
    .products
      justify-content: center
#product.theme #wrapper .container
  padding: 0
  .product-container
    .page-content,.product-variants,.product-reference,.desc_short,.product-information,.attchment-product
      padding: 0 20px
  .ac-container,.db_list_comments_products
    padding: 0 20px
