@import 'color.sass'
// @media (min-width: 420px)
//
@media (min-width: 500px)
  .dbhomecategories
    .row
      li
        &:nth-child(1)
          width: 50%
          padding-right: 2px
        &:nth-child(2)
          width: 50%
          padding-left: 2px
        &:nth-child(3)
          width: 50%
          padding-right: 2px
        &:nth-child(4)
          width: 50%
          padding-left: 2px
        &:nth-child(5)
          max-height: 160px
          a
            height: 100%
            img
              height: 100%
              object-fit: cover
//
// @media (min-width: 576px)
//
@media (min-width: 768px) and (max-width: 1200px)
  .header__flex .position-static.displayTop.hidden-sm-down
    margin-right: 25px
  .mm_columns_ul
    border: 0 !important
    padding-top: 0 !important
  .pc .mm_menus_li .mm_columns_ul::after
    display: none
  .mm_blocks_li
    margin-bottom: 0 !important
  .theme .ets_mm_megamenu .ets_mm_megamenu_content_content .ybc-menu-toggle
    top: -125px
  .theme .mm_menus_li:hover .mm_columns_ul, .theme .mm_menus_li.menu_hover .mm_columns_ul, .theme .ets_mm_megamenu:not(.scroll_heading) .mm_menus_li.menu_ver_alway_show_sub .mm_columns_ul
    z-index: 0
    max-height: 0
    border: 0
  .mm_menus_li:hover .mm_columns_ul, .mm_menus_li.menu_hover .mm_columns_ul, .ets_mm_megamenu:not(.scroll_heading) .mm_menus_li.menu_ver_alway_show_sub .mm_columns_ul
    opacity: 0
    visibility: hidden
  .theme .mm_menus_li .mm_columns_ul.active, .theme .mm_menus_li.menu_hover .mm_columns_ul.active, .theme .ets_mm_megamenu:not(.scroll_heading) .mm_menus_li.menu_ver_alway_show_sub .mm_columns_ul.active
    z-index: 9999
    max-height: 500px
    border: 0
    margin: 0
  .transition_default .mm_menus_ul.active
    .icon-arrowr
      display: none
  .mm_menus_li .mm_columns_ul.active, .mm_menus_li.menu_hover .mm_columns_ul.active, .ets_mm_megamenu:not(.scroll_heading) .mm_menus_li.menu_ver_alway_show_sub .mm_columns_ul.active
    opacity: 1
    visibility: visible

@media (min-width: 768px)
  #carousel figure .caption
    max-width: 675px
  .theme #envioGratis
    position: fixed
    top: 0
    bottom: unset
    z-index: 999999
  #header.pc
    padding-top: 30px
  #talleres>div
    width: 49%
  .theme #wrapper .breadcrumb
    span,a
      font-size: 20px
      line-height: 23px
  #js-product-list-header .block-category .block-category-inner p, #js-product-list-header .block-category .block-category-inner h1
    margin-bottom: 15px
  .theme #wrapper
    .breadcrumb
      margin-bottom: 60px
      li:after
        font-size: 20px
  #bannercms a, .bannercms a
    font-size: 30px
    line-height: 35px
    max-width: 100%
  #bannercms a::before
    margin-right: 10px
  #bannercms a::after
    margin-left: 10px
  #iconscms
    .iconcircle
      width: 145px
      span:nth-child(1)
        width: 135px
        height: 135px
        &::before
          font-size: 59px
      span:nth-child(2)
        font-size: 20px
        line-height: 20px
  .titlecms,.ap5-pack-page h1
    font-size: 30px
    line-height: 34px
    margin-bottom: 45px
  #wrapper .breadcrumb
    margin-bottom: 40px
  #contact
    .mapa
      height: 413px
      margin-top: 35px
    #pfg-form-1 .h3, #pfg-form-1 h3
      font-size: 30px
      line-height: 34px
      margin-bottom: 44px
    #content-wrapper
      #socialBlock a span::before
        font-size: 57px
      .phones a span::before
        font-size: 35px
      .dir a
        font-size: 20px
        line-height: 26px
  #product
    .titp
      font-size: 20px
      line-height: 26px
    .attrSelected
      font-size: 26px
      line-height: 30px
    .product-prices .current-price
      span
        font-size: 26px
      .old_price
        font-size: 14px
    .product-variants ul li.input-container .name
      font-size: 14px
      line-height: 16px
    .product-variants ul li.input-container
      width: 96px
      label
        width: 96px
        height: 96px
    .titleVariants
      font-size: 30px
      line-height: 34px
    #content
      max-width: 100%
    .product-actions
      padding: 0 40px
      display: flex
      flex-wrap: wrap
      form
        order: 1
      #fixedProduct
        order: 2
      .product-reference
        order: 3
        width: 100%
        padding: 8px 0
        margin-bottom: 8px
        span
          font-size: 16px
          line-height: 26px
      .desc_short
        order: 4
  .theme .product-miniature .product-title, .theme #products .product-miniature .product-title,
  .theme .product-miniature .product-description .product-price-and-shipping, .theme #products .product-miniature .product-description .product-price-and-shipping
    font-size: 16px
    line-height: 16px
  p, #seo p, #seo2 p
    font-size: 16px
    line-height: 26px
  #seo .seovisible .tituloseo h1, #seo .seovisible .tituloseo .h1, #seo2 .seovisible .tituloseo h1, #seo2 .seovisible .tituloseo .h1,
  .products-section-title, .h3.title, .h3.products-section-title
    font-size: 30px
    line-height: 34px
  .carousel .carousel-item .caption
    max-width: 530px
  #carousel figure .caption
    .title
      font-size: 16px
    .desc
      margin: 20px 0
      p
        font-size: 30px
        line-height: 34px
    .link
      font-size: 16px
  #footer .footer-container #linklist
    width: 66%
    & > .row
      display: flex
  #footer .footer-container .links .col-md-12:last-child .h3
    margin-top: 0
  #splide_newproducts>div, #splide_featuredproducts>div, #splide_bestseller>div, .hc-products-list>div
    width: 33%
    padding: 0 5px
  #splide_newproducts, #splide_featuredproducts, #splide_bestseller,.hc-products-list
    justify-content: center
  .header-nav
    display: none
  #header
    .header-top
      // padding-bottom: 5px
      padding-bottom: 25px
      border: 1px solid rgba(50, 51, 102, 0.2)
      .container
        width: 100%
        padding: 0
        .content_flex
          border-bottom: 1px solid rgba(50, 51, 102, 0.2)
          width: 100%
          margin-bottom: 22px
          .header__flex
            padding: 0
            width: 100%
            max-width: 1200px
            margin: 0 auto
            #search_widget
              min-width: 300px
              input
                border: .5px solid #515151
            .displayTopCenter
              width: auto
              .container
                width: auto
                #_desktop_top_menu
                  margin-bottom: 0
                  .top-menu
                    margin-bottom: 0
                    li
                      a
                        font-family: "karla"
                        font-style: normal
                        font-weight: 400
                        font-size: 14px
                        line-height: 16px
                        color: $colort2
                        text-transform: capitalize
//
// @media (min-width: 800px)
//
// @media (min-width: 900px)
//
// @media (max-width: 950px)
//
@media (min-width: 992px)
  #splide_newproducts>div, #splide_featuredproducts>div, #splide_bestseller>div, .hc-products-list>div
    width: 25%
//
// @media (min-width: 1050px)
//
@media (min-width: 1200px)
  #carousel figure .caption
    .desc .h1
      font-size: 60px
      line-height: 60px
    .title
      padding: 20px 
  #category,#new-products,#prices-drop
    #left-column
      width: 22%
    #content-wrapper
      width: 78%
  #iconscms
    margin-bottom: 50px
  #bannercms, .bannercms
    margin-bottom: 22px
  #pfg-form-1 #gdpr_consent span, #pfg-form-1 #gdpr_consent a, #pfg-form-2 #gdpr_consent span, #pfg-form-2 #gdpr_consent a
    font-size: 16px
    line-height: 16px
  #pfg-form-1 .form-group.row input, #pfg-form-1 .form-group.row textarea, #pfg-form-2 .form-group.row input, #pfg-form-2 .form-group.row textarea
    padding: 7px 20px
  .theme #seo2
    padding-top: 35px
  .theme .hc-mode-list
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15)
    padding-top: 15px
    padding-bottom: 30px
  .theme .featured-products.bestsellers
    max-width: 100% !important
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15) !important
    .products-section-title,#splide_bestseller,.homecategorylink
      max-width: 1270px
      margin: 0 auto
      width: 100%
      padding-bottom: 35px
  .theme .dbhomecategories
    padding-bottom: 35px
    margin-top: 15px
  .theme #wrapper .breadcrumb
    margin-top: 15px
  #js-product-list-header .block-category h1, #js-product-list-header .block-category .h1,#js-product-list-header .block-category .categoryextrafield p
    font-size: 30px
    line-height: 34px
  #js-product-list-header .block-category p
    font-size: 16px
    line-height: 26px
  #footer .footer-container
    & > .container
      display: flex
      flex-wrap: wrap
      & > .row
        width: 75%
  #footer #infoFooter.row
    width: 25%
  #category,#new-products,#prices-drop
    .category-sub-menu li[data-depth="0"]>a
      margin: 0
    .closeTree
      display: none
    .container.pc
      display: flex
      flex-wrap: wrap
      width: 100%
      max-width: 1200px
      margin: 0 auto
      .breadcrumb
        width: 100%
      #left-column
        width: 22%
        .block-categories
          padding: 0
        #search_filters>.h6
          display: block
          text-align: left
        #search_filters
          .facet
            .facet-title
              padding: 0
            .facet-label
              display: inline-flex
              align-items: center
              .custom-checkbox,.custom-radio,.search-link
                margin: 0
                top: unset
              .custom-checkbox
                margin-right: 5px
                display: none
              .search-link
                color: $colort
                transition: all .1s linear
                &:hover
                  color: $color1
            .facet-label.active
              .search-link
                color: $color1
                font-weight: 600
          .title
            pointer-events: none
            border-bottom: 0
          .collapse-icons
            display: none
          ul
            display: block
            list-style: disc
            padding-left: 20px
            li::marker
              color: $colort
        .category-sub-menu
          list-style: disc
          padding-left: 20px
          li[data-depth="0"] > a
            padding-bottom: 0
            border: 0
          li
            &::marker
              color: $colort
      #content-wrapper
        width: 78%
      .full-column
        width: 100%
//
// @media (min-width: 1200px)
@media (min-width: 1270px)
  .dbhomecategories,.featured-products.bestsellers
    box-shadow: unset
@media (max-width: 1220px)
  .theme .mm_menus_ul:not(.active) li a span
    font-size: 13px
    padding: 10px 10px
  .mm_columns_ul>.mm_has_sub>.mm_blocks_ul>.mm_blocks_li>.ets_mm_block>.ets_mm_block_content>.ets_mm_categories>li>a,
  .theme .pc .ets_mm_megamenu.disable_sticky_mobile .mm_blocks_li .ets_mm_block .ets_mm_block_content .ets_mm_categories>li.has-sub>a
    font-size: 12px
    line-height: 14px
  .theme .pc .ets_mm_megamenu.disable_sticky_mobile
    // max-width: 830px
@media (max-width: 1200px)
  #header .header-top .header__flex .txticon
    display: none
  .theme .mm_menus_ul:not(.active) li a span
    padding: 18px 10px
  #category,#new-products,#prices-drop
    .container.pc
      display: flex
      flex-wrap: wrap
    .closeTree
      // display: none
  .pc #left-column .category-top-menu>li:nth-child(3),.pc #left-column #search_filters .facet.clearfix,.pc .block-categories
    padding: 0
  .layout_layout1.ets_mm_megamenu .mm_columns_ul, .layout_layout1.ybc_vertical_menu .mm_menus_ul
    max-width: 600px

@media (max-width: 991px)
  #cart .cart-container .cart-item .cart__product-qty
    width: 40%
  #cart .cart-container .cart-item .cart__product-price
    width: 60%
@media (max-width: 870px)
  .theme .mm_menus_ul:not(.active) li a span
    font-size: 12px
    white-space: nowrap
@media (max-width: 768px)
  #carousel figure .caption .link
    padding: 5px 10px
  .right
    #_mobile_wishlist_link
      margin-right: 15px
    #_mobile_cart .blockcart
      margin-left: 0 !important
  #product.theme #wrapper .container .product-container
    .product-reference
      padding-top: 10px
      padding-bottom: 10px
    .desc_short
      padding-top: 10px
  #linklist
    margin-bottom: 15px
  #footer .block_newsletter input[type="email"]
    max-width: 250px
  #product .product-container
    margin: 0
    & > .col-md-6
      width: 100%
  .theme #splide_images_product_miniature .nxtal-wishlist-icon
    bottom: 20px
    right: 20px
    span::before
      font-size: 32px
@media (max-width: 650px)
  #products .products>div, .featured-products .products>div, .product-accessories .products>div, .product-miniature .products>div
    width: 50%
  .cart-grid-body .card-block h1, .cart-summary .cart-summary_header, .js-cart .cart-summary_header
    font-size: 20px
  #slidernosotros
    .slick-slide
      & > div
        img
          width: 150px !important
          height: 120px
@media (max-width: 600px)
  #iconscms .iconcircle
    width: 33%
    margin-bottom: 25px
