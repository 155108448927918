@import 'color.sass'

*:focus
  outline: 0 !important
a
  color: $colorb
a,.dbhomecategories .home_cat:hover .name
  text-decoration: none !important
a:focus
  outline: unset !important
  outline-offset: unset !important
  text-decoration: none !important
#cms
  h1,h2,h3,h4,.h1,.h2,.h3,.h4
    color: $color1
.btnd
  padding: 10px 15px
  background: $colorb
  color: $color3
  font-weight: 600
  font-size: 14px
  line-height: 16px
  text-align: center
  letter-spacing: 0.05em
  text-transform: uppercase
  width: auto
  transition: all .1s linear
  &:hover
    color: $color3
    background: $color1
.btn-primary
  padding: 10px 15px
  background: $colorb
  color: $color3
  font-weight: 600
  font-size: 14px
  line-height: 16px
  text-align: center
  letter-spacing: 0.05em
  text-transform: uppercase
  width: auto
  transition: all .1s linear
  &:hover
    color: $color3
    background: $color1
.row
  margin-left: 0
  margin-right: 0
#wrapper
  background: $color3
  box-shadow: unset
  padding: 0
  background: transparent
  .container
    padding: 0
    width: 100%
.mt-3
  margin-top: 0
.page-footer
  display: none
.theme .dbbrandslide
  width: 100%
  margin: 0
p
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 20px
  letter-spacing: 0.05em
  color: $colort
.product-flags li.product-flag.discount, .product-flags li.product-flag.discount-amount, .product-flags li.product-flag.discount-percentage
  background-color: $color1
.product-flags li.product-flag
  background: $colorb
.theme .product-flags li.product-flag
  font-size: 12px
  min-width: auto !important
  min-height: auto !important
  padding: 3px 5px
.products-section-title,.h3.title, .h3.products-section-title
  font-family: "asap"
  font-weight: 600
  font-size: 22px
  line-height: 25px
  text-align: center
  color: $color1
  width: 100%
  text-align: center
  padding: 0 20px
  margin-bottom: 18px !important
a.hc-cat
  font-family: "asap" !important
  font-weight: 600 !important
  font-size: 22px !important
  line-height: 25px !important
  text-align: center !important
  color: $color1 !important
  width: 100% !important
  text-align: center !important
  padding: 0 20px !important
  margin-bottom: 18px !important
  justify-content: center !important
  border: 0 !important
  margin-bottom: 0 !important
  text-transform: initial !important
.theme .mt-3
  margin-top: 0 !important
#products .product-thumbnail, .featured-products .product-thumbnail, .product-accessories .product-thumbnail, .product-miniature .product-thumbnail
  display: inline-block
  overflow: hidden
  img,.product-title
    transition: all .2s linear
  .product-title
    background: $color3
  &:hover
    img
      transform: scale(1.1)
    .product-title
      transform: scale(1)
.layout_layout1 .ets_mm_megamenu_content
  border: 0 !important
#products .thumbnail-container:hover .product-thumbnail .product-title, .featured-products .thumbnail-container:hover .product-thumbnail .product-title, .product-accessories .thumbnail-container:hover .product-thumbnail .product-title, .product-miniature .thumbnail-container:hover .product-thumbnail .product-title
  text-decoration: none
.search-widget form
  input[type="text"]
    padding-left: 35px
    font-size: 12px
    color: $colort2
  button[type="submit"]
    display: inline-flex
    left: 5px
    top: 0
    bottom: 0
    margin-top: auto
    margin-bottom: auto
    justify-content: center
    align-items: center
    right: unset
    .icon-search
      &::before
        color: $color2
.blockcart
  .cart-products-count
    display: none
.modal-backdrop.in
  opacity: 0
#category #left-column #search_filters .facet,.modal-header
  border: 0
#category #left-column #search_filters .facet .title,#search_filters .facet .facet-title
  background-color: #fff
.theme #wrapper .breadcrumb
  padding: 10px 20px
  text-align: center
  margin-bottom: 0
  font-family: "asap"
  color: $colort2
  font-weight: 500
  font-size: 10px
  a,span
    color: $colort2
    font-weight: 500
    font-size: 10px
#search_filters .facet .title
  display: flex !important
#search_filters .facet .collapse, #search_filters_brands .facet .collapse, #search_filters_suppliers .facet .collapse
  display: none
#category #left-column #search_filters .facet .facet-title.hidden-sm-down
  display: none !important
#search_filters .facet .collapse.in, #search_filters_brands .facet .collapse, #search_filters_suppliers .facet .collapse.in
  display: block
#search_filters, #search_filters_brands, #search_filters_suppliers
  padding: 0
#products .products > div, .featured-products .products > div, .product-accessories .products > div, .product-miniature .products > div
  width: 33%
.block-category .block-category-inner #category-description .page-content.page-cms ul, .block-category .block-category-inner #category-description p, .page-content.page-cms .block-category .block-category-inner #category-description ul,
#product .product-description, #product .product-description p
  color: $colort
#js-product-list #js-product-list
  padding: 0
.block-category .block-category-inner
  flex-wrap: wrap
.bootstrap-touchspin #quantity_wanted, .bootstrap-touchspin #quantity_wanted_miniature
  border: 1px solid $colorb
  color: $colorb
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down,.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up
  background: $colorb
  border: 1px solid $colorb
  color: $color3
#product-modal
  z-index: 99999
  .modal-dialog
    max-width: 770px
  .modal-content .modal-body
    margin-left: 0
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.2)
    background: #fff
    .product-cover-modal
      background: $color3
      width: 100%
      max-width: 700px
      object-fit: contain
      padding: 20px
    figure
      width: 80%
    #thumbnails
      width: 20%
      .js-modal-product-images
        margin-left: 0
    .image-caption
      width: 100%
#product .btn-comment:hover, #product .btn-comment-inverse:hover
  transition: all .1s linear
  &:hover
    background: $colorb
.theme.page-cart,#my-account.theme,.page-customer-account.theme,#cms.theme,#contact.theme,.page-order-confirmation,#module-nxtalwishlist-wishlist,#order-detail
  #content-wrapper
    width: 100%
    max-width: 1240px
    padding: 0 20px !important
    margin: 0 auto
.theme.page-cart,.page-order-confirmation
  #content-wrapper
    padding: 40px 20px
.page-order
  #wrapper
    max-width: 1200px
    margin: 0 auto
    padding: 40px 20px
.ybc-blog
  .container > #content-wrapper
    width: 100%
    max-width: 1240px
    padding: 50px 20px !important
    margin: 0 auto
#userinfo_modal
  z-index: 99999
.cart-grid-body .card-block h1,.cart-summary .cart-summary_header, .js-cart .cart-summary_header
  color: $color1
#header .header-top .container .content_flex .header__flex .displayTopCenter .container #_desktop_top_menu .top-menu li a
  transition: all .1s linear
  &:hover
    color: $colorb
#_desktop_user_info, #_desktop_cart
  span::before
    transition: all .1s linear
  &:hover
    span::before
      color: $colorb
#envioGratis
  display: flex
  width: 100%
  background: $colorb
  color: $color3
  text-align: center
  justify-content: center
  padding: 5px 20px
  position: sticky
  bottom: 0
  z-index: 9999
.layout_layout1 .mm_menus_li.mm_has_sub:hover > a:after, .layout_layout1 .mm_menus_li.mm_has_sub.menu_hover > a:after,
.transition_floating .ybc-menu-toggle.opened:before, .transition_full .ybc-menu-toggle.opened:before,
body .ets_mm_megamenu::before,#header .layout_layout1 .ets_mm_block_content ul li a::before
  display: none
body:not(.pc)
  .mm_menus_ul
    background: $color3
  .close_menu
    display: none !important
  .layout_layout1 .mm_menus_li
    border: 0
header:not(.pc)
  .mm_menus_ul
    .arrow::before,.arrow::after
      display: none
    .icon-arrowr::before
      transition: all .1s linear
      display: inline-block
    .arrow.opened
      .icon-arrowr::before
        transform: rotate(90deg)
  .novedades
    position: relative
    overflow: hidden
    &::before
      display: block !important
      content: ""
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: rgba(0,0,0,0.3)
      position: absolute
      z-index: 2
    img
      width: 100%
      height: 100%
      position: absolute
      object-fit: cover
      transition: all .1s linear
      z-index: 1
    a span,span,a
      width: 100%
      color: $color3 !important
      background: transparent !important
    &:hover
      img
        transform: scale(1.1)
header:not(.pc)
  .ets_mm_megamenu .mm_columns_ul
    margin-top: 0
  .hiddenpc
    span
      font-size: 14px
      line-height: 16px
      letter-spacing: 0.05em
      color: $colort2
      text-transform: capitalize
      font-weight: 500
  .hiddenpc:nth-child(9)
    border-top: 1px solid rgba(50, 51, 102, 0.2)
    margin-top: 10px
header.pc
  .hiddenpc
    display: none
  .novedades
    img
      display: none
.theme
  .mm_menus_li:hover .mm_columns_ul, .mm_menus_li.menu_hover .mm_columns_ul, .ets_mm_megamenu:not(.scroll_heading) .mm_menus_li.menu_ver_alway_show_sub .mm_columns_ul
    z-index: 99999
    max-height: 500px
.ets_mm_megamenu:not(.transition_slide) .mm_columns_ul
  max-height: 0
  overflow: hidden
#main .page-header
  text-align: center
  display: none
#main .page-content #notifications,#contact #main .page-content.card-block,.page-content.page-cms
  margin: 0
.page-content.page-cms
  padding: 0
.theme
  .ybc-blog-form-comment .blog-submit .button, .blog-managament-information button[type="submit"], .ybc-blog-slider.carousel .slick-dots .slick-active button,
  .blog_view_all, .view_all_link, .blog_view_all_button a, .form-polls-body button, .form_reply input.btn.btn-primary.btn-default[name="addReplyComment"],
  .add_new_post_blog a, #form_blog button[type="submit"], #form_blog .btn.edit_view_post, .form_reply_comment input[type="submit"], .ets_blog_loading .squaresWaveG
    background: $colorb
    border: 1px solid $colorb
    transition: all .1s linear
    &:hover
      background: $color3
      color: $colorb !important
  #left-column .ybc_block_categories a, #left_column .ybc_block_categories a, #right-column .ybc_block_categories a, #right_column .ybc_block_categories a
    color: $colorb
    &:hover
      color: $color1
.theme
  .adveg-grecaptcha-inline
    margin-bottom: 20px
    justify-content: center !important
body#checkout #footer
  padding: initial
#js-product-list-header
  .block-category
    .block-category-inner
      p,h1
        margin-bottom: 6px
      .categoryextrafield
        margin: 0
        padding-bottom: 0
        p,h1
          margin-bottom: 0
.ui-menu.ui-jolisearch
  z-index: 9999999 !important
.cms-id-7
  strong
    color: $colorb
.block-categories .collapse-icons .add:hover, .block-categories .collapse-icons .remove:hover
  color: $colorb
.block-categories .category-sub-menu li:not([data-depth="0"]):not([data-depth="1"]):before
  display: none
.theme .btn-primary.focus,.theme .btn-primary:focus,.theme .btn-primary:hover
  background: $color1
.nxtal-wishlist-cart .icon-wish::before
  color: $color3
#splide_images_product_miniature .product-cover .layer
  background: rgba(0, 0, 0, 0.2)
  transition: all .1s linear
.theme .dbbrandslide
  box-shadow: 0px 0px 5px rgba(0,0,0,0.15)
  padding: 5px 0
  .title
    display: none
.footer-container .container .row
  margin-left: 0
  margin-right: 0
#wrapper .breadcrumb li:after
  content: "|"
.theme #category-description p:last-child
  margin-bottom: 0 !important
.pagination .text__infinitescroll
  margin-bottom: 0

.theme .pfg-forms
  .submit.row
    text-align: center
    margin: 0
#blockcart-modal
  z-index: 999999
#cart
  #content-wrapper
    margin-top: 50px
  .titlecount,.h1
    color: $color1
    font-weight: 700
    text-transform: inherit
  .card.nxtal-wishlist-cart
    border: 0
  .nxtal-wishlist-icon
    background: transparent
    top: 30px
    span::before
      font-size: 20px
      color: $color1
      transition: all .1s linear
    &:hover
      span::before
        color: red
  .nxtal-wishlist-icon.active
    span::before
      color: red
.theme
  .btn-primary, .btn-secondary, .btn-tertiar
    box-shadow: unset
  .register-form
    .form-group
      margin-bottom: 15px
.form-control-label
  top: 1.5rem
#checkout
  .form-group
    display: inline-block
    margin-bottom: 10px
    width: 100%
#camaracomercio
  width: 100%
  max-width: 1200px
  margin: 0 auto
  padding: 25px 20px
  padding-bottom: 0
#module-ambjolisearch-jolisearch.theme #products .product-miniature
  max-width: 300px
  margin: 0 auto
.promo-input
  margin-bottom: 15px
.nostock.btnd
  background: #f54891
  display: inline-flex
  align-items: center
  &:hover
    background: #f54891
strong
  color: #515151 !important
  font-weight: 600 !important
.ap5-pack-page
  h1
    font-family: "asap"
    font-weight: 600
    font-size: 22px
    line-height: 25px
    text-align: center
    color: #7980c2
    margin-bottom: 25px
    display: inline-block
    width: 100%

.alert.alert-warning.row
  display: none

.payment-options .custom-radio input
  margin: 0

.thumbnail-container .add .btnd
  display: inline-block

.whatsappf
  display: inline-block
  position: fixed
  right: 20px
  bottom: 65px
  z-index: 9999